import React, { useContext, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Icon,
  FormControl,
  FormLabel,
  FormHelperText,
  Textarea,
  SimpleGrid,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";

import {
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
  AiFillCloseCircle,
} from "react-icons/ai";
import { maintenance, message } from "../../data/maintenance";
import { CartContext } from "../../context/CartContext";
import ItemSelectionFront from "./ItemSelectionFront";

function Popup(props) {
  const { cart, setCart, subTotal, setSubTotal } = useContext(CartContext);
  const [quantity, setQuantity] = useState(1);
  const [note, setNote] = useState("");
  const [scrollBehavior, setScrollBehavior] = React.useState("outside");
  //delete this for kens
  const [size, setSize] = useState("small");
  const [soup, setSoup] = useState("Wonton Soup");

  //popup center exemption
  const centerExemption = ["D1", "D2", "D3", "D4", "D6", "D8"];

  //Dinner for one
  const [pickedItems, setPickedItems] = useState([]);
  const [dinnerOneChoice, setDinnerOneChoice] = useState("1");
  const [trigger, setTrigger] = useState(false);

  const handleChange = (value) => {
    setPickedItems([]);
    setDinnerOneChoice(value);
  };

  //soups 20% | quantity > 2
  const soupList = ["U1", "U2", "U3", "U4", "U5"];

  const minus = () => {
    if (quantity !== 1) {
      setQuantity(quantity - 1);
    }
  };

  const add = () => {
    if (quantity < 19) {
      setQuantity(quantity + 1);
    }
  };

  const noteHandler = (e) => {
    setNote(e.target.value);
  };

  const getComboOnePrice = () => {
    let defaultComboOnePrice = props.item.prices.oneItem.price.toFixed(2);

    if (dinnerOneChoice === "2") {
      defaultComboOnePrice = props.item.prices.twoItem.price.toFixed(2);
    } else if (dinnerOneChoice === "3") {
      defaultComboOnePrice = props.item.prices.threeItem.price.toFixed(2);
    }

    return defaultComboOnePrice;
  };

  function RoundNum(num, length) {
    var number = Math.round(num * Math.pow(10, length)) / Math.pow(10, length);
    return number;
  }

  const addToCart = (item) => {
    const tempCart = [...cart];

    let soupDiscountPrice = 0;
    let soupDiscountQuantity = quantity - 1;
    let soupDiscountFlag = false;
    const soupDiscountPercentage = 0.2;

    let skipAddSoup = false;
    if (
      tempCart.some((x) => x.name === item.name) &&
      soupList.includes(item.number)
    ) {
      tempCart.forEach((obj) => {
        if (obj.name === item.name) {
          obj.quantity += quantity;
          if (obj.quantity >= 1) {
            //needed
            obj.soupDiscountFlag = true;
            soupDiscountFlag = true;
            let tempAmountOff =
              RoundNum(item.price * soupDiscountPercentage, 2) *
              (obj.quantity - 1);
            let discountedSoups = RoundNum(
              item.price * (obj.quantity - 1) - tempAmountOff,
              2
            );
            soupDiscountPrice = RoundNum(item.price + discountedSoups, 2);
            obj.soupDiscountPrice = soupDiscountPrice;
          }
        }
      });

      skipAddSoup = true;
    } else if (soupList.includes(item.number) && quantity > 1) {
      soupDiscountFlag = true;
      let tempAmountOff =
        RoundNum(item.price * soupDiscountPercentage, 2) * soupDiscountQuantity;
      let discountedSoups = RoundNum(
        item.price * soupDiscountQuantity - tempAmountOff,
        2
      );
      soupDiscountPrice = RoundNum(item.price + discountedSoups, 2);
    }

    //handle dinner for one price
    let dOnePrice = 0;
    if (item.special) {
      if (dinnerOneChoice === "1") {
        dOnePrice = item.prices.oneItem.price;
      } else if (dinnerOneChoice === "2") {
        dOnePrice = item.prices.twoItem.price;
      } else if (dinnerOneChoice === "3") {
        dOnePrice = item.prices.threeItem.price;
      }
    }

    const itemObj = {
      name: item.name,
      number: item.number,
      price: item.special ? dOnePrice : item.price,
      comboPickedItems: pickedItems,
      includes: item.includes,
      quantity: quantity,
      note: note,
      combo: item.combo,
      special: item.special,
      chineseName: item.chineseName,
      soupDiscountFlag: soupDiscountFlag,
      soupDiscountPrice: soupDiscountPrice,
    };

    if (!skipAddSoup) {
      tempCart.push(itemObj);
    }
    let tempSubTotal = 0;
    tempCart.forEach((x) => {
      if (x.soupDiscountFlag) {
        tempSubTotal += x.soupDiscountPrice;
      } else {
        tempSubTotal += x.price * x.quantity;
      }
    });

    //console.log("picked obj", itemObj);

    setSubTotal(RoundNum(tempSubTotal, 2));
    setDinnerOneChoice("1");
    setNote("");
    setPickedItems([]);
    setCart(tempCart);
    setQuantity(1);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={() => {
        setQuantity(1);
        setPickedItems([]);
        setDinnerOneChoice("1");
        props.onClose();
      }}
      size="xl"
      isCentered={centerExemption.includes(props.item.number) ? false : true}
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent
        ml={{ base: "0.5rem", md: "0rem" }}
        mr={{ base: "0.5rem", md: "0rem" }}
      >
        {maintenance() ? (
          <ModalHeader width="95%">
            <Text>{message()}</Text>
          </ModalHeader>
        ) : (
          <ModalHeader width="95%">
            {props.item.number
              ? props.item.number + ". " + props.item.name
              : props.item.name}
          </ModalHeader>
        )}
        <ModalCloseButton />

        <ModalBody>
          {maintenance() ? null : (
            <FormControl>
              {props.item.combo || props.item.special ? (
                <>
                  <Text>
                    <b>Includes: </b>
                    {props.item.includes.map((incItem) => {
                      return incItem.name + ", ";
                    })}
                  </Text>

                  {props.item.special ? (
                    <RadioGroup
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={dinnerOneChoice}
                      mt="1rem"
                    >
                      <Flex direction="column" fontWeight="bold">
                        <Radio size="md" value="1">
                          One Item - $
                          {props.item.prices.oneItem.price.toFixed(2)}
                        </Radio>
                        <Radio size="md" value="2">
                          Two Item - $
                          {props.item.prices.twoItem.price.toFixed(2)}
                        </Radio>
                        <Radio size="md" value="3">
                          Three Item - $
                          {props.item.prices.threeItem.price.toFixed(2)}
                        </Radio>
                      </Flex>
                    </RadioGroup>
                  ) : null}

                  <Text mb="1rem" fontWeight="bold" fontSize="lg" mt="1rem">
                    Select: {props.item.choices}{" "}
                    {props.item.choices === "1" ? "Item" : "Items"}
                  </Text>
                  <Flex
                    direction="column"
                    border={"1px solid #c4c4c4"}
                    minHeight="85px"
                    borderColor="#c4c4c4"
                    borderRadius="5px"
                    justify="center"
                  >
                    {pickedItems.map((comboItem, i) => {
                      return (
                        <Text ml="5px" mr="5px" key={"comboI" + i}>
                          -{comboItem.code}: {comboItem.name}
                        </Text>
                      );
                    })}
                  </Flex>
                  <SimpleGrid columns={2} gap={3} mt="1rem">
                    {props.item.selection &&
                      props.item.selection.map((selection, j) => {
                        if (selection.hidden !== true) {
                          return (
                            <ItemSelectionFront
                              key={"item-sel" + j}
                              selection={selection}
                              choices={
                                props.item.special
                                  ? dinnerOneChoice
                                  : props.item.choices
                              }
                              pickedItems={pickedItems}
                              setPickedItems={(pickedItems) => {
                                setPickedItems(pickedItems);
                              }}
                              trigger={trigger}
                              setTrigger={(pickedItems) =>
                                setTrigger(pickedItems)
                              }
                            ></ItemSelectionFront>
                          );
                        } else {
                          return null;
                        }
                      })}
                    <Button
                      fontSize={{ base: "14px" }}
                      leftIcon={<AiFillCloseCircle />}
                      colorScheme="red"
                      onClick={() => {
                        let tempPickedItems = pickedItems;
                        tempPickedItems.pop();
                        setPickedItems(tempPickedItems);
                        setTrigger(!trigger);
                      }}
                    >
                      Remove
                    </Button>
                  </SimpleGrid>
                </>
              ) : null}

              <FormLabel htmlFor="special-instructions" mt="1rem">
                Special Instructions
              </FormLabel>
              <Textarea
                type="text"
                id="special-instructions"
                resize="none"
                maxLength="175"
                borderColor="#c4c4c4"
                onChange={(e) => noteHandler(e)}
              />
              <FormHelperText>Example: No Mushrooms</FormHelperText>
            </FormControl>
          )}
        </ModalBody>

        {maintenance() ? null : (
          <ModalFooter as={Flex} justify="space-between">
            <Flex width="50%" align="center" justify="center">
              <Flex
                align="center"
                width={{ base: "75%", md: "55%" }}
                justify="space-between"
              >
                <Icon
                  w={7}
                  h={7}
                  cursor="pointer"
                  as={AiOutlineMinusCircle}
                  onClick={() => minus()}
                  opacity={quantity === 1 ? "40%" : "100%"}
                />
                <Text>{quantity}</Text>
                <Icon
                  w={7}
                  h={7}
                  cursor="pointer"
                  as={AiOutlinePlusCircle}
                  onClick={() => add()}
                  opacity={quantity === 9 ? "40%" : "100%"}
                />
              </Flex>
            </Flex>
            <Button
              width="70%"
              variant="solid"
              bg="brand.black"
              color="brand.white"
              _hover={{ bg: "#494949" }}
              isDisabled={
                props.item.special &&
                pickedItems.length.toString() === dinnerOneChoice
                  ? false
                  : props.item.combo &&
                    pickedItems.length.toString() === props.item.choices
                  ? false
                  : props.item.special === undefined &&
                    props.item.combo === undefined
                  ? false
                  : true
              }
              onClick={() => {
                addToCart(props.item);
                setQuantity(1);
                props.onClose();
              }}
            >
              Add to Cart -
              {props.item.special
                ? " $" + getComboOnePrice()
                : " $" + (props.item.price * quantity).toFixed(2)}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}

export default Popup;
