import React from "react";
import { Flex, Text, Icon } from "@chakra-ui/react";
import { data } from "./kowloonMenu";
import { FaPepperHot } from "react-icons/fa";
export default function CategorySection({ category, index, marginFlag }) {
  return (
    <Flex
      direction="column"
      width="calc(100% - 10px)"
      alignSelf="center"
      mt={marginFlag ? "8px" : "0px"}
    >
      <Flex direction="column" ml="6px" mr="6px" mt="8px">
        <Flex mb="2px" align="center">
          <Text
            fontWeight="bold"
            fontSize="17px"
            mr="4px"
            color="brand.darkred"
          >
            {category}
          </Text>
          <Text as="i" mt="2px">
            {category === "Soup" ? "(20% Off On The 2nd Same Soup)" : null}
          </Text>
        </Flex>
        {data.menu[index].items.map((item, i) => {
          return (
            <Flex key={"i" + i} direction="column">
              <Flex justify="space-between" align="center" width="100%">
                <Text mr="8px" fontSize="12px" align="end" width="13%">
                  {item.number + "."}
                </Text>

                <Flex direction="column" width="100%">
                  <Flex
                    overflow="hidden"
                    whiteSpace="nowrap"
                    fontSize="12px"
                    align="center"
                    _after={{
                      content: `""`,
                      borderBottom: "1px dashed black",
                      marginLeft: "5px",
                      marginRight: "5px",
                      flex: 1,
                    }}
                  >
                    {item.name}
                    {item.spicy ? (
                      <Icon
                        width="9px"
                        height="9px"
                        ml="5px"
                        as={FaPepperHot}
                      />
                    ) : null}
                  </Flex>
                </Flex>

                <Text alignSelf="flex-start" fontSize="13px">
                  ${item.price.toFixed(2)}
                </Text>
              </Flex>

              {item.desc !== undefined ? (
                <Flex justify="space-between" align="center" mt="-3px">
                  <Text
                    mr="7px"
                    fontSize="13px"
                    align="end"
                    width="13%"
                    opacity={0}
                  >
                    {item.number + "."}
                  </Text>

                  <Flex direction="column" width="100%">
                    <Flex
                      overflow="hidden"
                      whiteSpace="wrap"
                      fontSize="13px"
                      align="center"
                      _after={{
                        content: `""`,
                        borderBottom: "1px dashed black",
                        marginLeft: "5px",
                        marginRight: "5px",
                        flex: 1,
                        opacity: 0,
                      }}
                    >
                      <Text lineHeight="12px" fontSize="12px">
                        {item.desc}
                      </Text>
                      {item.spicy ? (
                        <Icon
                          width="9px"
                          height="9px"
                          ml="5px"
                          as={FaPepperHot}
                          opacity={0}
                        />
                      ) : null}
                    </Flex>
                  </Flex>

                  <Text alignSelf="flex-start" fontSize="13px" opacity={0}>
                    ${item.price.toFixed(2)}
                  </Text>
                </Flex>
              ) : null}
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}
