import { Button, Link, Flex, Text, Image } from "@chakra-ui/react";

import React from "react";

import { messageBannerFlag } from "../../data/maintenance";

function Landing(props) {
  return (
    <Flex
      justify="center"
      align="center"
      position="relative"
      width="100%"
      height="100%"
      minHeight={{ base: "750px", lg: "850px" }}
      mt={{ base: "80px" }}
      borderWidth={{ base: "10px", md: "15px" }}
      borderColor="brand.red"
      bg="brand.red"
      top={messageBannerFlag ? (props.takeoutmode ? "0px" : "40px") : "0px"}
    >
      <Flex
        justify="center"
        width="100%"
        height="100%"
        borderWidth={{ base: "5px", sm: "10px", md: "15px" }}
        borderColor="brand.beige"
        bg="brand.red"
        color="brand.beige"
        fontFamily="glory"
        fontSize={{ base: "subheader", sm: "main" }}
        textAlign={{ base: "center", sm: "start" }}
        sx={{
          borderImage: "url(/border.png)",
          borderImageSource: "url(/border.png)",
          borderImageSlice: "70",
          borderImageRepeat: "stretch",
          borderImageWidth: { base: "30px", sm: "40px", md: "50px" },
        }}
      >
        <Flex position="absolute" height="100%" width="100%">
          <Image
            src="/landingpattern.png"
            objectFit="cover"
            objectPosition="50% 50%"
            width="100%"
            height="100%"
            alt="Ken's Kowloon Kitchen Banner"
          ></Image>
        </Flex>
        <Flex
          direction="column"
          justify="center"
          align={{ base: "center", sm: "initial" }}
          width="100%"
          height="100%"
          maxWidth="1440px"
          pl={{ base: "10px", sm: "20px", md: "70px", lg: "100px" }}
          pr={{ base: "10px", sm: "20px", md: "70px", lg: "100px" }}
        >
          <Text fontWeight={700} mb="15px">
            Pick Up | Delivery | Dine-in
          </Text>
          <Text
            mb="35px"
            fontFamily="langar"
            fontSize={{ base: "32px", sm: "40px", md: "48px" }}
            letterSpacing={{ base: -0.5, sm: -1.5 }}
            lineHeight={1}
          >
            <Text as="span" fontSize={{ base: "40px", sm: "52px", md: "64px" }}>
              S
            </Text>
            avor{" "}
            <Text as="span" fontSize={{ base: "40px", sm: "52px", md: "64px" }}>
              C
            </Text>
            hinese{" "}
            <Text as="span" fontSize={{ base: "40px", sm: "52px", md: "64px" }}>
              F
            </Text>
            lavours
          </Text>

          <Flex direction={{ base: "column", sm: "row" }}>
            <Button
              as={Link}
              maxWidth={{ base: "175px", sm: "200px" }}
              width="100%"
              height="100%"
              href="/menu.pdf"
              fontFamily="glory"
              fontSize={{ base: "mobile.buttonSize", sm: "buttonSize" }}
              fontWeight={700}
              letterSpacing={{ base: "-0.75px", sm: 0 }}
              bg="brand.beige"
              color="brand.red"
              mr={{ base: "0", sm: "24px" }}
              mb={{ base: "10px", sm: "0" }}
              borderRadius={5}
              _hover={{
                color: "brand.beige",
                bg: "brand.darkred",
                textDecoration: "none",
              }}
              isExternal
            >
              <Text
                pt={{ base: "28px", sm: "32px" }}
                pb={{ base: "28px", sm: "32px" }}
                pl={{ base: "28px", sm: "32px" }}
                pr={{ base: "28px", sm: "32px" }}
              >
                Our Menu
              </Text>
            </Button>
            <Button
              as={Link}
              maxWidth={{ base: "175px", sm: "200px" }}
              width="100%"
              height="100%"
              href="/takeout"
              fontFamily="glory"
              fontSize={{ base: "mobile.buttonSize", sm: "buttonSize" }}
              fontWeight={700}
              letterSpacing={{ base: "-0.75px", sm: 0 }}
              bg="brand.beige"
              color="brand.red"
              borderRadius={5}
              _hover={{
                color: "brand.beige2",
                bg: "brand.darkred",
                textDecoration: "none",
              }}
            >
              <Text
                pt={{ base: "28px", sm: "32px" }}
                pb={{ base: "28px", sm: "32px" }}
                pl={{ base: "28px", sm: "32px" }}
                pr={{ base: "28px", sm: "32px" }}
              >
                Order For Pick Up
              </Text>
            </Button>
          </Flex>
          <Text fontWeight={700} mt="40px">
            2118 Glenmore Court SE, Calgary
          </Text>
          <Text fontWeight={700}>(403) 279-4471</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Landing;
