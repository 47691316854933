import moment from "moment";

const day = moment().format("MMMM D");
const christmas = moment("12-25");

export const maintenance = () => {
  //christmas
  let maintenanceFlag = false;
  if (christmas.isSame(day, "day")) {
    maintenanceFlag = true;
  } else {
    maintenanceFlag = false;
  }
  //use for when ordering needs to be turned off and make sure to change the message
  return maintenanceFlag;
};

export const message = () => {
  //christmas
  let message;
  if (christmas.isSame(day, "day")) {
    message = "Ken's Kowloon Kitchen is closed for Christmas.";
  } else {
    message =
      "Our online ordering system is currently going through maintenance. Please call our restaurant (403-254-6908) to place your pick-up order.";
  }
  return message;
};

//toggle for the message banner to appear
export const messageBannerFlag = false;
export const messageBanner =
  "Mother's Day (Sunday) Hours - Noon to9:00PM Order Early To Avoid The Rush!";
