import React from "react";
import { Flex, Text, VStack, UnorderedList, ListItem } from "@chakra-ui/react";
function Offers(props) {
  return (
    <Flex direction="column" fontFamily="innerbody" align="flex-start">
      <VStack mt="3rem">
        <Text alignSelf="flex-start" fontSize="32px" fontWeight={700}>
          OFFERS
        </Text>
        <Text fontSize={{ base: "18px", md: "24px" }}>
          Discount will automatically be applied at checkout
        </Text>
      </VStack>

      <Flex mt="1rem" direction={{ base: "column", md: "row" }}>
        <Flex
          border="1px"
          borderColor="#C4C4C4"
          p={7}
          direction="column"
          width="100%"
          mr={{ base: "0rem", md: "1rem" }}
          mb={{ base: "1rem", md: "0rem" }}
        >
          <Text fontWeight={700}>
            Discount on pick-up orders over $40 (before GST)
          </Text>
          <UnorderedList>
            <ListItem>
              5% off <i>all</i> payment
            </ListItem>
          </UnorderedList>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Offers;
