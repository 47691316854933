import React from "react";
import {
  useDisclosure,
  Flex,
  Text,
  Icon,
  ListItem,
  SimpleGrid,
  UnorderedList,
} from "@chakra-ui/react";
import { FaPepperHot } from "react-icons/fa";
import Popup from "./Popup";

import { offline } from "../../data/offline";
import ClosePopup from "./ClosePopup";
import moment from "moment";
moment().format();

function ItemButton({ item }) {
  const {
    isOpen: isItemOpen,
    onOpen: onItemOpen,
    onClose: onItemClose,
  } = useDisclosure();
  const {
    isOpen: isClosedOpen,
    onOpen: onClosedOpen,
    onClose: onClosedClose,
  } = useDisclosure();

  return (
    <>
      <Flex
        border="1px"
        borderRadius="0px"
        borderColor="#c4c4c4"
        variant="outline"
        direction="column"
        align="center"
        fontFamily="body"
        fontWeight={700}
        fontSize="14px"
        justify="center"
        pt="2rem"
        pb="2rem"
        onClick={() => {
          let timeFlag = offline();
          if (timeFlag) {
            onItemOpen();
          } else {
            onClosedOpen();
          }
        }}
        _hover={{
          boxShadow: "base",
          bg: "brand.white",
          cursor: "pointer",
        }}
      >
        <Flex justify="space-between" width="93%">
          <Flex justify="flex-start" direction="column">
            <Flex align="center">
              <Text>{item.number + ". " + item.name}</Text>
              {item.spicy ? (
                <Icon
                  transform="scaleX(-1)"
                  ml="0.5rem"
                  mr="0.6rem"
                  as={FaPepperHot}
                />
              ) : null}
            </Flex>
            <Text fontWeight={400}>{item.desc}</Text>
          </Flex>
          <Text>
            {item.special
              ? "$" + item.prices.oneItem.price
              : "$" + item.price.toFixed(2)}
          </Text>
        </Flex>
        {item.combo || item.special ? (
          <Flex width="93%">
            <Flex align="flex-start" direction="column">
              <Text mt="0.5rem">Includes</Text>
              <SimpleGrid columns={2} spacingX={3} fontFamily="body" ml="5px">
                {item.includes.map((item, i) => {
                  return (
                    <Flex key={"dinner-set-item: " + i} direction="column">
                      <UnorderedList fontWeight={400} fontSize="14px">
                        <ListItem>{item.name}</ListItem>
                      </UnorderedList>
                    </Flex>
                  );
                })}
              </SimpleGrid>
            </Flex>
          </Flex>
        ) : (
          ""
        )}
      </Flex>
      <Popup
        item={item}
        isOpen={isItemOpen}
        onOpen={onItemOpen}
        onClose={onItemClose}
      ></Popup>
      <ClosePopup
        isOpen={isClosedOpen}
        onOpen={onClosedOpen}
        onClose={onClosedClose}
      ></ClosePopup>
    </>
  );
}

export default ItemButton;
