import React, { useContext, useRef, useEffect } from "react";
import { Flex, Text, Button, Divider } from "@chakra-ui/react";
import { useReactToPrint } from "react-to-print";
import { AiFillPrinter } from "react-icons/ai";

import { TakeoutContext } from "../../context/TakeoutContext";
import { PrintSummaryWrapper } from "./Printing/PrintSummaryWrapper";
function Summary(props) {
  const { orderHistory, setOrderHistory } = useContext(TakeoutContext);

  //console.log("order", orderHistory);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    let sessionData = sessionStorage.getItem("orderHistory");
    if (JSON.parse(sessionData) !== null) {
      setOrderHistory(JSON.parse(sessionData));
    }
  }, []);
  return (
    <Flex width="100%" justify="center">
      <Flex width="90%" direction="column">
        <Flex justify="space-between" width="100%" mt="1rem" mb="1rem">
          <Text fontSize="2xl" fontWeight="bold">
            Daily Report
          </Text>
          <Button
            leftIcon={<AiFillPrinter />}
            colorScheme="green"
            variant="solid"
            onClick={handlePrint}
            width="150px"
          >
            Print
          </Button>
        </Flex>

        <PrintSummaryWrapper
          orderHistory={orderHistory}
          handlePrint={handlePrint}
          ref={componentRef}
        ></PrintSummaryWrapper>
      </Flex>
    </Flex>
  );
}

export default Summary;
