import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [admin, setUser] = useState();
  const [loading, setLoading] = useState(true);

  const login = async (email, password) => {
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      //console.log(error.message);
    }
  };

  const logout = async () => {
    await signOut(auth);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (admin) => {
      setUser(admin);
      setLoading(false);
    });
  }, []);

  const value = { admin, login, logout };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
