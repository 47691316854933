import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
} from "@chakra-ui/react";

function ClosePopup(props) {
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        isCentered
        size="xl"
        blockScrollOnMount
        preserveScrollBarGap
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>We are currently closed!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column">
              <Text fontWeight={700} mb="0.5rem">
                Business Hours
              </Text>
              <Text fontSize="15px">Tuesday - Friday</Text>
              <Text fontSize="15px">
                <b>Lunch:</b> 11:00AM - 1:30PM
              </Text>
              <Text fontSize="15px">Tuesday - Sunday</Text>
              <Text fontSize="15px">
                <b>Dinner:</b> 3:30PM - 9:00PM
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter justify="flex-start">
            <Flex direction="column">
              <Text fontSize="14px">
                *Last call for online orders at 1:15PM and 9:00PM
              </Text>
              <Text fontSize="14px">
                **The above is subject to change at any given time
              </Text>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ClosePopup;
