import React from "react";
import { Flex } from "@chakra-ui/react";
import Navbar from "../components/main-page/Navbar";
import Landing from "../components/main-page/Landing";
import Location from "../components/main-page/Location";
import About from "../components/main-page/About";
import ComboForOne from "../components/main-page/ComboForOne";
import Catering from "../components/main-page/Catering";

import Footer from "../components/main-page/Footer";
import MessageBanner from "../components/main-page/MessageBanner";
import { messageBannerFlag } from "../data/maintenance";

function Home(props) {
  return (
    <Flex width="100%" justify="center" bg="brand.beige" overflow="hidden">
      <Flex direction="column" width={{ base: "100%", "3xl": "1500px" }}>
        {messageBannerFlag ? <MessageBanner></MessageBanner> : null}
        <Navbar></Navbar>
        <Landing></Landing>
        <Location></Location>
        <About></About>
        <ComboForOne></ComboForOne>
        <Catering></Catering>

        <Flex justify="center" w="100%" bg="brand.red" p="56px 20px">
          <Footer></Footer>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Home;
