import React from "react";
import { Flex, Box, Text, Button, Link } from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa";

function Location() {
  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      justify="center"
      align="center"
      width="100%"
      bg="brand.beige"
      pt={{ base: "150px", sm: "150px" }}
      pb={{ base: "150px", sm: "150px" }}
      pl={{ base: "10px", xl: "300px" }}
      pr={{ base: "10px", xl: "150px" }}
    >
      <Flex
        direction="column"
        mr={{ base: "0", lg: "100px", xl: "150px" }}
        ml={{ base: "0", lg: "100px", xl: "0" }}
        mb={{ base: "60px", lg: "0" }}
        fontFamily="glory"
        fontSize={{ base: "mobile.main", sm: "main" }}
        lineHeight={1}
      >
        <Flex direction="column" align="center" mb="40px">
          <Text
            fontFamily="langar"
            fontSize={{ base: "mobile.header", sm: "header" }}
            letterSpacing={{ base: 0, sm: -0.5 }}
          >
            <Text as="span" fontSize={{ base: "32px", sm: "40px" }}>
              L
            </Text>
            ocation &{" "}
            <Text as="span" fontSize={{ base: "32px", sm: "40px" }}>
              H
            </Text>
            ours
          </Text>
          <Text
            fontFamily="glory"
            fontSize={{ base: "mobile.chineseLetter", sm: "chineseLetter" }}
            fontWeight="bold"
            letterSpacing="3.2px"
            mt="10px"
          >
            地址&营业时间
          </Text>
        </Flex>
        <Flex direction="column" align="center" mb="40px">
          <Text mb="5px">2118 Glenmore Court SE (Bay 4)</Text>
          <Text>Calgary, AB</Text>
        </Flex>
        <Flex direction="column" align="center" mb="40px">
          <Text fontWeight="bold" mb="10px">
            LUNCH
          </Text>
          <Flex>
            <Flex
              direction="column"
              textAlign="end"
              whiteSpace="nowrap"
              mr="20px"
            >
              <Text mb="5px" textAlign="start">
                Tuesday - Friday
              </Text>
              <Text textAlign="start">Saturday - Monday</Text>
            </Flex>
            <Flex direction="column" whiteSpace="nowrap">
              <Text mb="5px">11:00am - 1:30pm</Text>
              <Text>Closed</Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="column" align="center">
          <Text fontWeight="bold" mb="10px">
            DINNER
          </Text>
          <Flex>
            <Flex
              direction="column"
              textAlign="end"
              whiteSpace="nowrap"
              mr="20px"
            >
              <Text textAlign="start" mb="5px">
                Tuesday - Sunday
              </Text>
              <Text textAlign="start" mb="5px">
                Monday
              </Text>
            </Flex>
            <Flex direction="column" whiteSpace="nowrap">
              <Text mb="5px">3:30pm - 9:00pm</Text>
              <Text>Closed</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        direction="column"
        align="center"
        justify="center"
        bg="#EEE5D3"
        p={{ base: "35px", sm: "50px" }}
      >
        <Box
          mb="25px"
          height={{ base: "400px", sm: "400px", xl: "450px" }}
          width={{ base: "300px", sm: "300px", xl: "400px" }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1234.385881855997!2d-113.99728136832931!3d50.980572306584435!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537171f9a0059df9%3A0x977dcaeb8bfe6d37!2sKen&#39;s%20Chinese%20Restaurant!5e0!3m2!1sen!2sca!4v1700980698476!5m2!1sen!2sca"
            title="kens-kowloon-kitchen"
            width="100%"
            height="100%"
            style={{ borderRadius: "10px" }}
          ></iframe>
        </Box>

        <Button
          as={Link}
          href="https://www.google.com/maps/dir//Ken's+Chinese+Restaurant,+2118+Glenmore+Ct+SE,+Calgary,+AB+T2C+2E6/@50.9810437,-113.9992285,16z/data=!4m17!1m7!3m6!1s0x537171f9a0059df9:0x977dcaeb8bfe6d37!2sKen's+Chinese+Restaurant!8m2!3d50.9810195!4d-113.9964041!16s%2Fg%2F11f658rb2t!4m8!1m0!1m5!1m1!1s0x537171f9a0059df9:0x977dcaeb8bfe6d37!2m2!1d-113.9964041!2d50.9810195!3e3?hl=en&entry=ttu"
          leftIcon={<FaMapMarkerAlt />}
          height="fit-content"
          p="16px 24px"
          color="#EEE5D3"
          bg="brand.red"
          fontFamily="glory"
          fontSize={{ base: "mobile.buttonSize", sm: "buttonSize" }}
          fontWeight={700}
          letterSpacing={{ base: "-0.75px", sm: 0 }}
          _hover={{ bg: "brand.darkred", textDecoration: "none" }}
          isExternal
        >
          Get Directions
        </Button>
      </Flex>
    </Flex>
  );
}

export default Location;
