import React from "react";
import PrintPdf from "./PrintPdf";

//https://github.com/gregnb/react-to-print/issues/404
export class PrintPdfWrapper extends React.Component {
  render() {
    return (
      <PrintPdf
        takeoutCart={this.props.takeoutCart}
        orderPrice={this.props.orderPrice}
        name={this.props.name}
        phone={this.props.phone}
        address={this.props.address}
        method={this.props.method}
        currentInvoiceNumber={this.props.currentInvoiceNumber}
        time={this.props.time}
        history={this.props.history}
        orderNote={this.props.orderNote}
        table={this.props.table}
        skipCode={this.props.skipCode}
      ></PrintPdf>
    );
  }
}
