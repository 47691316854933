import React, { useState, useContext } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
} from "@chakra-ui/react";

import { TakeoutContext } from "../../context/TakeoutContext";

function ConfirmModal(props) {
  const initialRef = React.useRef(null);
  const { orderHistory, setOrderHistory, orderPrice } =
    useContext(TakeoutContext);
  const dayjs = require("dayjs");

  var now = dayjs();

  const [error, setError] = useState(false);

  function RoundNum(num, length) {
    var number = Math.round(num * Math.pow(10, length)) / Math.pow(10, length);
    return number;
  }

  const getOrderPrices = (orderIndex, customerOrders) => {
    //should align with PrintFormat's obj of orderPrices
    const taxRate = 0.05;
    let discount = 0.0;
    let originalSubTotal = parseFloat(customerOrders[orderIndex].data.subTotal);
    let tempSubTotal = originalSubTotal;
    let extraCharges = 0.0;

    //add the extra charges to subtotal first before discount
    if (customerOrders[orderIndex].data.additionalCharges > 0) {
      extraCharges = customerOrders[orderIndex].data.additionalCharges;
    } else {
      extraCharges = 0.0;
    }

    tempSubTotal += parseFloat(extraCharges);
    let subTotalDiscount = tempSubTotal;

    //apply 5% discount on pickup orders over $40.0
    if (tempSubTotal >= 40.0) {
      discount = RoundNum(tempSubTotal * taxRate, 2);
      subTotalDiscount -= discount;
    }

    let gst = RoundNum(subTotalDiscount * taxRate, 2);
    let total = RoundNum(subTotalDiscount + gst, 2);

    const tempOrderPricesObj = {
      subTotal: originalSubTotal,
      discount: discount,
      subTotalDiscount: subTotalDiscount,
      additionalCharges: extraCharges,
      deliveryFee: 0,
      gst: gst,
      total: total,
    };

    return tempOrderPricesObj;
  };

  const toggleConfirmOrder = () => {
    let tempCustomerOrders = [...props.customerOrders];
    tempCustomerOrders[props.index].confirmed = true;

    //add online order to order history
    //reformat obj
    let tempP = getOrderPrices(props.index, tempCustomerOrders);
    let orderObj = {
      name: tempCustomerOrders[props.index].data.name,
      phone: tempCustomerOrders[props.index].data.phone,
      address: "",
      currentInvoiceNumber: "Online",
      method: "5",
      time: now.format("HH:mm"),
      takeoutCart: tempCustomerOrders[props.index].data.cart,
      orderPrice: tempP,
      orderNote: tempCustomerOrders[props.index].data.additionalNotes,
      toggle: false,
    };

    let tempOrderHistory = orderHistory;
    tempOrderHistory.unshift(orderObj);

    //save to sessionstorage
    sessionStorage.removeItem("orders");
    sessionStorage.setItem("orders", JSON.stringify(tempCustomerOrders));
    sessionStorage.setItem("orderHistory", JSON.stringify(tempOrderHistory));

    props.setConfirm(tempCustomerOrders);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      initialFocusRef={initialRef}
      size="xl"
      isCentered
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Confirm Order For:{" "}
          {props.customerOrder !== null ? props.customerOrder.data.name : null}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {props.customerOrder !== null ? (
            props.customerOrder.data.time !== "ASAP" ? (
              <FormControl>
                <FormLabel>Enter Time (minutes)</FormLabel>
                <Input
                  isInvalid={error ? true : false}
                  ref={initialRef}
                  type="number"
                  onChange={(e) => {
                    props.setTime(e.target.value);
                  }}
                />
                <FormHelperText>Example: 15</FormHelperText>
              </FormControl>
            ) : (
              <Text>
                Picking up at:{" "}
                <i>
                  <b>{props.customerOrder.data.time}</b>
                </i>
              </Text>
            )
          ) : null}
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => {
              toggleConfirmOrder();

              if (props.customerOrder.data.time === "ASAP") {
                if (props.orderTime === undefined) {
                  setError(true);
                } else {
                  setError(false);
                  props.sendToAdmin();
                  props.onClose();
                }
              } else {
                props.sendToAdmin();
                props.onClose();
              }
            }}
            colorScheme="green"
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmModal;
