import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Flex,
  Text,
  SimpleGrid,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import "../../App.css";
import { kensSystemMenu } from "../../data/menu";

import {
  AiFillEdit,
  AiFillCar,
  AiFillPlusSquare,
  AiFillDollarCircle,
  AiFillFileUnknown,
} from "react-icons/ai";
import {
  Link,
  Element,
  Events,
  scrollSpy,
  animateScroll as scroll,
  scroller,
} from "react-scroll";
import { TakeoutContext } from "../../context/TakeoutContext";
import { ArrowLeftIcon, ArrowRightIcon, Search2Icon } from "@chakra-ui/icons";

import { navMenuList } from "../../data/menunav";
import TakeoutButton from "./TakeoutButton";
import TakeoutCart from "./TakeoutCart";
import NoteModal from "./Modals/NoteModal";
import DeliveryModal from "./Modals/DeliveryModal";
import ChargeModal from "./Modals/ChargeModal";
import NewModal from "./Modals/NewModal";
import LoadOrderModal from "./Modals/LoadOrderModal";

function TakeOutSystem() {
  const [navMenu, setNavMenu] = useState(navMenuList);
  const [search, setSearch] = useState("");

  const { setNextButton, method, setLoadOrderIndex, orderNote, setOrderNote } =
    useContext(TakeoutContext);

  const ref = useRef(null);

  const {
    isOpen: isOpenNew,
    onOpen: onOpenNew,
    onClose: onCloseNew,
  } = useDisclosure();

  const {
    isOpen: isOpenNote,
    onOpen: onOpenNote,
    onClose: onCloseNote,
  } = useDisclosure();

  const {
    isOpen: isOpenDelivery,
    onOpen: onOpenDelivery,
    onClose: onCloseDelivery,
  } = useDisclosure();

  const {
    isOpen: isOpenCharge,
    onOpen: onOpenCharge,
    onClose: onCloseCharge,
  } = useDisclosure();

  const {
    isOpen: isOpenLoad,
    onOpen: onOpenLoad,
    onClose: onCloseLoad,
  } = useDisclosure();

  let oldScrollY = 0;
  const [direction, setDirection] = useState("up");

  const controlDirection = () => {
    if (window.scrollY > oldScrollY) {
      setDirection("down");
    } else {
      setDirection("up");
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {
      //console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function (to, element) {
      //console.log("end", arguments);
    });
    window.addEventListener("scroll", controlDirection);

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", controlDirection);
    };
  }, []);

  const moveXBar = (dir) => {
    const offset = 210;

    if (dir === "right") {
      ref.current.scrollLeft += offset;
    } else {
      ref.current.scrollLeft -= offset;
    }
  };

  //function to move each category left or right by its bounding width
  const moveMenu = (category, tag, index) => {
    //console.log(category, tag, index);
    if (ref.current != null) {
      if (index > 0 && direction === "down") {
        let categoryWidthRight = ref.current.children[index - 1].clientWidth;
        ref.current.scrollLeft += categoryWidthRight;
      } else if (direction === "up") {
        let categoryWidthLeft = ref.current.children[index].clientWidth;
        ref.current.scrollLeft -= categoryWidthLeft;
      }
    }
  };

  return (
    <Flex width="100%" position="relative" pt={0}>
      <Flex
        width={{ base: "70%", xl: "83%" }}
        direction="column"
        align="center"
      >
        <Flex
          width="98%"
          mt="1rem"
          justify="flex-end"
          alignSelf="center"
          mb="0.5rem"
        >
          <Button
            colorScheme="pink"
            pl="30px"
            pr="30px"
            onClick={() => {
              setNextButton(0);

              setLoadOrderIndex();
            }}
          >
            Back
          </Button>
        </Flex>
        <Flex width="98%" mt="1rem" alignSelf="center" justify="space-between">
          <InputGroup width="300px">
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.500" />
            </InputLeftElement>
            <Input
              onChange={(event) => setSearch(event.target.value)}
              placeholder="Search Item"
            />
          </InputGroup>
        </Flex>
        <Flex
          width="98%"
          bg="brand.white"
          opacity="100%"
          position="sticky"
          top="0rem"
          pb="1.5rem"
          pt="1.5rem"
          zIndex={2}
          align="center"
        >
          <Flex
            p="6px"
            _hover={{ cursor: "pointer" }}
            onClick={() => moveXBar("left")}
          >
            <ArrowLeftIcon />
          </Flex>
          <Flex
            ref={ref}
            className="horizontal-scrollbar"
            overflowX="scroll"
            mr={{ base: "0rem", lg: "0.7rem" }}
            ml={{ base: "0rem", lg: "0.7rem" }}
            scrollBehavior="smooth"
            sx={{
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {navMenu.map((item, i) => {
              return (
                <Link
                  key={"key" + i}
                  activeClass="active"
                  className="category-links"
                  to={item.category}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-95}
                  onSetActive={(e, k) => {
                    moveMenu(e, k, i);
                  }}
                >
                  <Flex
                    width="max-content"
                    pl="1rem"
                    pr="1rem"
                    pt="0.3rem"
                    pb="0.3rem"
                    whiteSpace="nowrap"
                  >
                    <Text textAlign="center">{item.category}</Text>
                  </Flex>
                </Link>
              );
            })}
          </Flex>
          <Flex
            p="6px"
            _hover={{ cursor: "pointer" }}
            onClick={() => moveXBar("right")}
          >
            <ArrowRightIcon />
          </Flex>
        </Flex>
        <Flex
          direction="column"
          width={{ base: "98%" }}
          className="scroll-container"
          alignSelf="center"
        >
          {kensSystemMenu.menu
            .filter((categories) =>
              categories.items.some((item) => {
                return (
                  item.name.toLowerCase().includes(search.toLowerCase()) ||
                  item.number.toString().includes(search)
                );
              })
            )
            .map((item, i) => {
              return (
                <Flex
                  mb="2rem"
                  key={"category: " + i}
                  direction="column"
                  height="100%"
                >
                  <Element
                    name={item.category}
                    className="Element"
                    id={"e" + i}
                  >
                    <Text
                      fontFamily="innerbody"
                      fontWeight={700}
                      fontSize="24px"
                    >
                      {item.category}
                    </Text>
                    <Text fontFamily="innerbody">{item.info}</Text>

                    <SimpleGrid
                      columns={{ base: 2, lg: 3 }}
                      spacing={3}
                      mt="1rem"
                    >
                      {item.items
                        .filter((item) => {
                          return (
                            item.name
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            item.number.toString().includes(search)
                          );
                        })
                        .map((item, i) => {
                          return (
                            <TakeoutButton
                              key={"item: " + i}
                              item={item}
                            ></TakeoutButton>
                          );
                        })}
                    </SimpleGrid>
                  </Element>
                </Flex>
              );
            })}
        </Flex>
        <Flex
          position="sticky"
          bg="brand.white"
          borderTop="1px solid #c4c4c4"
          width="100%"
          bottom={0}
          pt="15px"
          pb="15px"
        >
          <Flex ml="10px" mr="10px" width="100%">
            <Button
              leftIcon={<AiFillPlusSquare />}
              colorScheme="orange"
              variant="solid"
              onClick={onOpenNew}
              mr="10px"
              pl="40px"
              pr="40px"
            >
              Save & New
            </Button>

            <Flex ml="auto">
              <Button
                leftIcon={<AiFillFileUnknown />}
                colorScheme="telegram"
                variant="solid"
                onClick={onOpenLoad}
                mr="10px"
                isDisabled={method !== "5" ? true : false}
              >
                Reload
              </Button>
              <Button
                leftIcon={<AiFillEdit />}
                colorScheme="telegram"
                variant="solid"
                onClick={onOpenNote}
                mr="10px"
              >
                Note
              </Button>
              <Button
                leftIcon={<AiFillCar />}
                colorScheme="telegram"
                variant="solid"
                onClick={onOpenDelivery}
                mr="10px"
                isDisabled={method === "2" ? false : true}
              >
                Fee
              </Button>
              <Button
                leftIcon={<AiFillDollarCircle />}
                colorScheme="telegram"
                variant="solid"
                onClick={onOpenCharge}
                mr="10px"
                isDisabled={method === "4" ? true : false}
              >
                + / -
              </Button>
            </Flex>

            <NewModal
              isOpenNew={isOpenNew}
              onOpenNew={onOpenNew}
              onCloseNew={onCloseNew}
              orderNote={orderNote}
              setOrderNote={(orderNote) => setOrderNote(orderNote)}
            ></NewModal>
            <NoteModal
              isOpenNote={isOpenNote}
              onOpenNote={onOpenNote}
              onCloseNote={onCloseNote}
              orderNote={orderNote}
              setOrderNote={(orderNote) => setOrderNote(orderNote)}
            ></NoteModal>
            <DeliveryModal
              isOpenDelivery={isOpenDelivery}
              onOpenDelivery={onOpenDelivery}
              onCloseDelivery={onCloseDelivery}
            ></DeliveryModal>
            <ChargeModal
              isOpenCharge={isOpenCharge}
              onOpenCharge={onOpenCharge}
              onCloseCharge={onCloseCharge}
            ></ChargeModal>
            <LoadOrderModal
              isOpenLoad={isOpenLoad}
              onOpenLoad={onOpenLoad}
              onCloseLoad={onCloseLoad}
            ></LoadOrderModal>
          </Flex>
        </Flex>
      </Flex>

      <TakeoutCart orderNote={orderNote}></TakeoutCart>
    </Flex>
  );
}

export default TakeOutSystem;
