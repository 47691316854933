import {
  Flex,
  HStack,
  Text,
  VStack,
  IconButton,
  Image,
  useDisclosure,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

import { HamburgerIcon } from "@chakra-ui/icons";
import MobileNav from "./MobileNav";
import React from "react";
import Cart from "../order-page/Cart";
import HoverMenu from "./HoverMenu";
import { messageBannerFlag } from "../../data/maintenance";

function Navbar(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  let location = useLocation();

  return (
    <Flex
      justify="center"
      align="center"
      width={{ base: "100%", "3xl": "1500px" }}
      height={{ base: "80px" }}
      position="fixed"
      bg="brand.beige"
      zIndex={5}
      boxShadow="0px 5px 5px 0px rgba(6, 20, 30, 0.02);"
      top={messageBannerFlag ? (props.takeoutmode ? "0px" : "40px") : "0px"}
    >
      <Flex
        align="center"
        justify="space-between"
        width={{ base: "100%" }}
        maxWidth="1500px"
        pl={{ base: "20px", md: "70px", lg: "100px" }}
        pr={{ base: "20px", md: "70px", lg: "100px" }}
        color="brand.red"
      >
        <Link to="/">
          <Flex align="center">
            <Image
              borderRadius="full"
              width={{ base: "40px", md: "50px" }}
              objectFit="contain"
              src="logored.png"
              alt="Ken's Kowloon Kitchen Logo"
            />
            <VStack align="flex-start" lineHeight={1} spacing={0} ml="10px">
              <Text
                fontFamily="langar"
                fontSize={{ base: "navbar.mobilelogo", sm: "navbar.logo" }}
              >
                Ken's Kowloon Kitchen
              </Text>
              <Text
                fontFamily="chinese"
                fontSize={{ base: "16px", sm: "18px" }}
              >
                九龙冰室
              </Text>
            </VStack>
          </Flex>
        </Link>

        <Flex
          fontSize="navbar.item"
          fontFamily="glory"
          fontWeight={700}
          justify="center"
          align="center"
          display={
            props.takeoutmode ? "flex" : ["none", "none", "flex", "flex"]
          }
        >
          {props.takeoutmode ? (
            <>
              <Flex
                mr="30px"
                borderColor="brand.beige"
                transition="all 0.1s ease-in-out"
                _hover={{ color: "brand.gold" }}
              >
                <Link to="/">Home</Link>
              </Flex>
              {location.pathname === "/checkout" ? null : <Cart></Cart>}
            </>
          ) : (
            <>
              <Flex
                mr="30px"
                borderColor="brand.beige"
                transition="all 0.1s ease-in-out"
                _hover={{ color: "brand.darkred" }}
              >
                <Link to="/">Home</Link>
              </Flex>
              <Flex mr="30px" borderBottom="1px" borderColor="brand.beige">
                <ChakraLink
                  href="/menu.pdf"
                  _hover={{ color: "brand.darkred" }}
                  isExternal
                >
                  Menu
                </ChakraLink>
              </Flex>

              <Flex
                mr="30px"
                borderColor="brand.beige"
                transition="all 0.1s ease-in-out"
                _hover={{ color: "brand.darkred" }}
              >
                <Link to="/takeout">Order Pick Up</Link>
              </Flex>

              <HoverMenu></HoverMenu>
            </>
          )}
        </Flex>

        {props.takeoutmode ? null : (
          <Flex
            display={["flex", "flex", "none", "none"]}
            align="center"
            justify="center"
          >
            <IconButton
              width="20px"
              height="20px"
              minWidth="20px"
              minHeight="20px"
              color="brand.red"
              aria-label="Dropdown Menu"
              variant="unstyled"
              size="lg"
              icon={
                <HamburgerIcon display="inherit" width="20px" height="20px" />
              }
              ref={btnRef}
              onClick={onOpen}
            />
            <MobileNav
              isOpen={isOpen}
              onClose={onClose}
              btnRef={btnRef}
            ></MobileNav>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default Navbar;
