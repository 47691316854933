import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Textarea,
  SimpleGrid,
  FormLabel,
  Flex,
  FormHelperText,
} from "@chakra-ui/react";
import { AiFillCloseCircle, AiFillExclamationCircle } from "react-icons/ai";
import { quickAccessOptions } from "../../../data/quickaccess";
export default function NoteModal({
  onOpenNote,
  isOpenNote,
  onCloseNote,
  orderNote,
  setOrderNote,
}) {
  const handleOrderNote = (options) => {
    setOrderNote(orderNote + " -" + options.option + ",");
  };

  return (
    <Modal
      isOpen={isOpenNote}
      onClose={() => {
        setOrderNote("");
        onCloseNote();
      }}
      isCentered
      blockScrollOnMount
      preserveScrollBarGap
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Custom Note</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Flex direction="column">
              <FormLabel
                fontWeight="bold"
                htmlFor="special-instructions"
                mt="1rem"
              >
                Quick Access
              </FormLabel>

              <SimpleGrid columns={3} gap={3}>
                {quickAccessOptions.map((options, i) => {
                  return (
                    <Button
                      width="100%"
                      size="sm"
                      variant="outline"
                      leftIcon={
                        options.special ? (
                          <AiFillExclamationCircle />
                        ) : (
                          <AiFillCloseCircle />
                        )
                      }
                      key={"opbn" + i}
                      colorScheme={options.special ? "blue" : "red"}
                      onClick={() => handleOrderNote(options)}
                    >
                      {options.option}
                    </Button>
                  );
                })}
              </SimpleGrid>
            </Flex>

            <FormLabel
              fontWeight="bold"
              htmlFor="special-instructions"
              mt="1rem"
            >
              Special Instructions
            </FormLabel>
            <Textarea
              type="text"
              id="special-instructions"
              resize="none"
              maxLength="175"
              borderColor="#c4c4c4"
              value={orderNote}
              onChange={(e) => {
                setOrderNote(e.target.value);
              }}
            />
            <FormHelperText>Example: No Pineapples</FormHelperText>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              setOrderNote("");
              onCloseNote();
            }}
          >
            Close
          </Button>
          <Button
            colorScheme="green"
            onClick={() => {
              onCloseNote();
            }}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
