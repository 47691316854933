import React from "react";
import { Flex, Text, Image } from "@chakra-ui/react";
import CategorySection from "./CategorySection";
import QRCode from "react-qr-code";
export default function PageOne() {
  const dinnerOneItems = [
    { item: "Ginger Beef", number: "Q1", spicy: true },
    { item: "Sweet & Sour Pork", number: "Q2", spicy: false },
    { item: "Lemon Chicken", number: "Q3", spicy: false },
    { item: "Dry Garlic Spareribs", number: "Q4", spicy: false },
    { item: "Chicken Balls", number: "Q5", spicy: false },
    { item: "Deep Fried Bubble Shrimp", number: "Q6", spicy: false },
    { item: "Chicken Chop Suey", number: "Q7", spicy: false },
    { item: "Chicken w/ Mixed Veggies", number: "Q8", spicy: false },
    { item: "Stir Fried Mixed Veggies", number: "Q9", spicy: false },
  ];

  const groupDinnerItems = [
    { item: "Ginger Beef", number: "D1", spicy: true },
    { item: "Sweet & Sour Pork", number: "D2", spicy: false },
    { item: "Lemon Chicken", number: "D3", spicy: false },
    { item: "Dry Garlic Spareribs", number: "D4", spicy: false },
    { item: "Chicken Balls", number: "D5", spicy: false },
    { item: "Deep Fried Bubble Shrimp", number: "D6", spicy: false },
    { item: "Chicken Chop Suey", number: "D7", spicy: false },
    { item: "Chicken w/ Mixed Veggies", number: "D8", spicy: false },
    { item: "Chicken Chow Mein", number: "D9", spicy: false },
    { item: "Chicken Shanghai Noodles", number: "D10", spicy: false },
  ];
  return (
    <>
      <Flex
        direction="column"
        width="25%"
        border="1px solid #A50104"
        bg="white"
      >
        <CategorySection
          category="Shanghai Noodles (Thick)"
          index={11}
          marginFlag={false}
        ></CategorySection>
        <CategorySection
          category="Chow Mein (Thin)"
          index={10}
          marginFlag={false}
        ></CategorySection>
        <CategorySection
          category="Extras"
          index={13}
          marginFlag={false}
        ></CategorySection>
      </Flex>
      <Flex
        direction="column"
        width="25%"
        border="1px solid #A50104"
        bg="white"
      >
        <Flex direction="column" width="100%" bg="white" fontSize="menumd">
          <Flex direction="column">
            <Text
              align="center"
              mt="15px"
              fontWeight="bold"
              fontSize="20px"
              color="brand.darkred"
            >
              Group Dinners
            </Text>
            <Text align="center" fontSize="15px" lineHeight="20px" mt="5px">
              All Group Dinners Include
            </Text>
            <Flex
              direction="column"
              width="80%"
              alignSelf="center"
              align="center"
            >
              <Text as="i" fontSize="15px" lineHeight="20px">
                - Spring Rolls
              </Text>
              <Text as="i" fontSize="15px" lineHeight="20px">
                - Wonton Soup
              </Text>
              <Text as="i" fontSize="15px" lineHeight="20px">
                - Chicken Fried Rice
              </Text>
              <Text as="i" fontSize="15px" lineHeight="20px">
                - Stir Fried Mixed Veggies
              </Text>
            </Flex>
          </Flex>
          <Flex
            direction="column"
            align="center"
            alignSelf="center"
            border="1px solid #A50104"
            width="90%"
            borderRadius="7px"
            mt="11px"
            pb="3px"
            pt="3px"
          >
            <Flex justify="space-between" width="75%" fontSize="16px">
              <Text fontWeight="bold">Dinner For Two</Text>
              <Text fontWeight="bold">$39.96</Text>
            </Flex>

            <Text fontSize="15px">
              <b>
                <u>1</u>
              </b>{" "}
              Choice from the list
            </Text>
          </Flex>
          <Flex
            direction="column"
            align="center"
            alignSelf="center"
            border="1px solid #A50104"
            width="90%"
            borderRadius="7px"
            mt="11px"
            pb="3px"
            pt="3px"
          >
            <Flex justify="space-between" width="75%" fontSize="16px">
              <Text fontWeight="bold">Dinner For Three</Text>
              <Text fontWeight="bold">$53.96</Text>
            </Flex>

            <Text fontSize="15px">
              <b>
                <u>2</u>
              </b>{" "}
              Choices from the list
            </Text>
          </Flex>
          <Flex
            direction="column"
            align="center"
            alignSelf="center"
            border="1px solid #A50104"
            width="90%"
            borderRadius="7px"
            mt="11px"
            pb="3px"
            pt="3px"
          >
            <Flex justify="space-between" width="75%" fontSize="16px">
              <Text fontWeight="bold">Dinner For Four</Text>
              <Text fontWeight="bold">$78.96</Text>
            </Flex>

            <Text fontSize="15px">
              <b>
                <u>3</u>
              </b>{" "}
              Choices from the list
            </Text>
          </Flex>
          <Flex
            direction="column"
            align="center"
            alignSelf="center"
            border="1px solid #A50104"
            width="90%"
            borderRadius="7px"
            mt="11px"
            pb="3px"
            pt="3px"
          >
            <Flex justify="space-between" width="75%" fontSize="16px">
              <Text fontWeight="bold">Dinner For Six</Text>
              <Text fontWeight="bold">$109.96</Text>
            </Flex>
            <Flex direction="column" width="100%" align="center">
              <Text fontSize="14px">- Chicken Fried Rice x 2</Text>
            </Flex>
            <Text fontSize="15px">
              <b>
                <u>4</u>
              </b>{" "}
              Choices from the list
            </Text>
          </Flex>
          <Flex
            direction="column"
            align="center"
            alignSelf="center"
            border="1px solid #A50104"
            width="90%"
            borderRadius="7px"
            mt="11px"
            pb="3px"
            pt="3px"
          >
            <Flex justify="space-between" width="75%" fontSize="16px">
              <Text fontWeight="bold">Dinner For Eight</Text>
              <Text fontWeight="bold">$138.96</Text>
            </Flex>

            <Flex direction="column" width="100%" align="center">
              <Text fontSize="14px">- Chicken Fried Rice x 2</Text>
              <Text lineHeight="20px" fontSize="14px">
                - Stir Fried Mixed Veggies x 2
              </Text>
            </Flex>

            <Text fontSize="15px">
              <b>
                <u>5</u>
              </b>{" "}
              Choices from the list
            </Text>
          </Flex>
          <Flex
            direction="column"
            align="center"
            alignSelf="center"
            border="1px solid #A50104"
            width="90%"
            borderRadius="7px"
            mt="11px"
            pb="3px"
            pt="3px"
          >
            <Text fontWeight="bold" as="i" align="center" fontSize="16px">
              Group Dinner Choices
            </Text>
            <Flex direction="column" mt="5px" mb="5px" alignSelf="center">
              {groupDinnerItems.map((items, i) => {
                return (
                  <Text key={"cbi" + i} fontSize="14px">
                    {items.number}. {items.item}
                  </Text>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        direction="column"
        width="25%"
        border="1px solid #A50104"
        bg="white"
      >
        <Flex direction="column" width="100%" bg="white" fontSize="menulg">
          <Text
            align="center"
            mt="15px"
            fontWeight="bold"
            fontSize="20px"
            color="brand.darkred"
          >
            Combo For One
          </Text>

          <Text align="center" mt="15px" mb="5px">
            Combo For One Includes
          </Text>

          <Flex
            direction="column"
            width="80%"
            alignSelf="center"
            align="center"
          >
            <Text as="i" fontSize="16px" lineHeight="20px">
              - Spring Roll (1)
            </Text>
            <Text as="i" fontSize="16px" lineHeight="20px">
              - Chicken Fried Rice{" "}
            </Text>
          </Flex>

          <Text align="center" mt="15px">
            <b>
              <u>1</u>
            </b>{" "}
            Choice from the list
          </Text>
          <Text align="center" fontWeight="bold">
            $12.96
          </Text>

          <Text align="center">
            <b>
              <u>2</u>
            </b>{" "}
            Choices from the list
          </Text>
          <Text align="center" fontWeight="bold">
            $14.96
          </Text>
          <Text align="center">Extra Side Order</Text>
          <Text align="center" fontWeight="bold">
            $6.96
          </Text>

          <Flex
            direction="column"
            align="center"
            alignSelf="center"
            border="1px solid #A50104"
            width="90%"
            borderRadius="7px"
            mt="20px"
          >
            <Text fontWeight="bold" as="i" align="center">
              Combo For One Choices
            </Text>
            <Flex
              direction="column"
              mt="5px"
              mb="5px"
              alignSelf="center"
              fontSize="menumd"
            >
              {dinnerOneItems.map((items, i) => {
                return (
                  <Text key={"cbi" + i} fontSize="15px">
                    {items.number}. {items.item}
                  </Text>
                );
              })}
            </Flex>
          </Flex>
        </Flex>

        <Flex
          width="100%"
          align="center"
          mt="auto"
          direction="column"
          mb="15px"
        >
          <Text as="i">Extra Charges For Substitutions Not On List</Text>
          <Text as="i">Prices Subject to Change Without Notice</Text>
        </Flex>
      </Flex>
      <Flex
        direction="column"
        width="25%"
        border="1px solid #A50104"
        bg="white"
      >
        <Flex direction="column" width="100%" bg="white" fontSize="menulg">
          <Flex width="100%" justify="center">
            <Image
              mt="15px"
              width="50px"
              objectFit="contain"
              src="logored.png"
              alt="Ken's Kowloon Kitchen Logo"
            />
            <Flex direction="column" ml="10px">
              <Text
                fontSize="2xl"
                as="i"
                color="brand.darkred"
                fontFamily="langar"
                mt="15px"
                fontWeight="bold"
              >
                Ken's Kowloon Kitchen
              </Text>

              <Text
                fontSize="2xl"
                color="brand.darkred"
                fontFamily="chinese"
                lineHeight="20px"
                fontWeight="bold"
              >
                九龙冰室
              </Text>
            </Flex>
          </Flex>
          <Text mt="15px" align="center">
            Pick Up | Delivery | Dine-in
          </Text>
          <Text align="center" fontWeight="bold" mt="0.5rem">
            403-279-4471 | 403-279-4472
          </Text>

          <Text align="center">2118 Glenmore Court SE</Text>
          <Text align="center" fontSize="menumd" lineHeight="13px">
            By Glenmore Inn Hotel
          </Text>

          <Text
            align="center"
            fontWeight="bold"
            as="i"
            fontSize="menulg"
            letterSpacing="0.4px"
            mt="15px"
            color="brand.darkred"
          >
            kenskowloonkitchen.com
          </Text>
          <Text align="center" fontSize="menumd" lineHeight="10px" mt="5px">
            Order Pickup Online
          </Text>

          <Flex
            direction="column"
            align="center"
            alignSelf="center"
            border="1px solid #A50104"
            width="90%"
            borderRadius="7px"
            mt="20px"
            pt="5px"
            pb="5px"
          >
            <Text fontWeight="bold" as="i" align="center">
              Business Hours
            </Text>

            <Flex width="100%" direction="column" mt="5px">
              <Flex direction="column" ml={2} mr={2}>
                <Text fontWeight="bold">Lunch</Text>
                <Flex justify="space-between" fontSize="menusm">
                  <Text whiteSpace="nowrap">Tuesday - Friday</Text>
                  <Text whiteSpace="nowrap">11:00AM - 1:30PM</Text>
                </Flex>
                <Flex justify="space-between" fontSize="menusm">
                  <Text whiteSpace="nowrap">Saturday - Monday</Text>
                  <Text whiteSpace="nowrap">Closed</Text>
                </Flex>
              </Flex>

              <Flex direction="column" ml={2} mr={2}>
                <Text fontWeight="bold">Dinner</Text>
                <Flex justify="space-between" fontSize="menusm">
                  <Text whiteSpace="nowrap">Tuesday - Sunday</Text>
                  <Text whiteSpace="nowrap">3:30PM - 9:00PM</Text>
                </Flex>
                <Flex justify="space-between" fontSize="menusm">
                  <Text whiteSpace="nowrap">Monday</Text>
                  <Text whiteSpace="nowrap">Closed</Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            direction="column"
            align="center"
            alignSelf="center"
            border="1px solid #A50104"
            width="90%"
            borderRadius="7px"
            mt="15px"
            pt="5px"
            pb="5px"
          >
            <Text fontWeight="bold" as="i" align="center">
              Promotion
            </Text>
            <Text align="center" fontSize="menumd">
              <b>5%</b> Off <b>Pick-Up</b> Orders Over <i>$40</i>
            </Text>

            {/*
              <Text
              align="center"
              fontSize="menumd"
              mt={1}
              mr={1}
              ml={1}
              mb="-5px"
            >
              Free <b>Chicken Fried Rice</b> over <i>$60</i>
            </Text>
            <Text as="i" align="center" fontSize="menusm" mb="5px">
              (Not applicable to group dinners)
            </Text>
              */}
          </Flex>

          <Flex
            direction="column"
            align="center"
            alignSelf="center"
            border="1px solid #A50104"
            width="90%"
            borderRadius="7px"
            mt="15px"
            pt="5px"
            pb="5px"
          >
            <Text fontWeight="bold" as="i" align="center">
              Free Delivery
            </Text>
            <Text fontSize="menusm" align="center">
              On orders over <i>$40</i> to the following areas
            </Text>
            <Text m={1} as="i" fontWeight="bold" fontSize="13px" align="center">
              Ogden, Lynnwood, Riverbend, Quarry Park, Douglasglen
            </Text>

            <Text fontSize="menusm" align="center" mt="7px">
              On orders over <i>$60</i> to the following areas
            </Text>
            <Text m={1} as="i" fontWeight="bold" fontSize="13px" align="center">
              Douglasdale, McKenzie, New Brighton, New Brighton, South View,
              Dover, Erin Woods
            </Text>
          </Flex>

          <Flex
            justify="space-between"
            alignSelf="center"
            width="90%"
            mt="25px"
          >
            <QRCode size={60} value={"http://kenskowloonkitchen.com/"} />
            <Flex fontSize="14px" direction="column" mt="10px">
              <Text as="i">Catering Available To Any Party Size</Text>
              <Text as="i">Contact Us For More Info</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
