export const navMenuList = [
  { category: "Dinner Combo", toggle: false },
  { category: "Appetizers", toggle: false },
  { category: "Soup", toggle: false },
  { category: "Beef", toggle: false },
  { category: "Pork", toggle: false },
  { category: "Chicken", toggle: false },
  { category: "Seafood", toggle: false },
  { category: "Vegetables", toggle: false },
  { category: "Chop Suey", toggle: false },
  { category: "Egg Foo Yung", toggle: false },
  { category: "Rice", toggle: false },
  { category: "Shanghai Noodles (Thick)", toggle: false },
  { category: "Chow Mein (Thin)", toggle: false },
  { category: "Extras", toggle: false },
];

export const frontMenuList = [
  { category: "Dinner Combo", toggle: false },
  { category: "Appetizers", toggle: false },
  { category: "Soup", toggle: false },
  { category: "Beef", toggle: false },
  { category: "Pork", toggle: false },
  { category: "Chicken", toggle: false },
  { category: "Seafood", toggle: false },
  { category: "Vegetables", toggle: false },
  { category: "Chop Suey", toggle: false },
  { category: "Egg Foo Yung", toggle: false },
  { category: "Rice", toggle: false },
  { category: "Shanghai Noodles (Thick)", toggle: false },
  { category: "Chow Mein (Thin)", toggle: false },
  { category: "Extras", toggle: false },
];
