import { Flex } from "@chakra-ui/react";
import React from "react";
import PrintFormat from "./PrintFormat";

function PrintPdf({
  takeoutCart,
  orderPrice,
  name,
  phone,
  address,
  method,
  currentInvoiceNumber,
  history,
  time,
  orderNote,
  table,
  skipCode,
}) {
  return (
    <Flex width="100%">
      <Flex width="50%">
        <PrintFormat
          takeoutCart={takeoutCart}
          orderPrice={orderPrice}
          name={name}
          phone={phone}
          address={address}
          method={method}
          currentInvoiceNumber={currentInvoiceNumber}
          time={time}
          history={history}
          orderNote={orderNote}
          table={table}
          copy={true}
          skipCode={skipCode}
        ></PrintFormat>
      </Flex>
      <Flex width="50%">
        <PrintFormat
          takeoutCart={takeoutCart}
          orderPrice={orderPrice}
          name={name}
          phone={phone}
          address={address}
          method={method}
          currentInvoiceNumber={currentInvoiceNumber}
          time={time}
          history={history}
          orderNote={orderNote}
          table={table}
          copy={false}
          skipCode={skipCode}
        ></PrintFormat>
      </Flex>
    </Flex>
  );
}

export default PrintPdf;
