import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import PrintTable from "./PrintTable";

function PrintSummary({ orderHistory }) {
  const dayjs = require("dayjs");
  var now = dayjs();

  let tempOrderHistory = orderHistory.filter((order, i) => {
    return order.method !== "4";
  });

  return (
    <Flex width="100%" direction="column">
      <Flex alignSelf="center" mt="20px" direction="column" align="center">
        <Text fontWeight="bold" fontSize="3xl">
          Ken's - Daily Report
        </Text>
        <Text fontWeight="bold" fontSize="3xl">
          {now.format("YYYY-MM-DD")}
        </Text>
      </Flex>
      <PrintTable orderHistory={tempOrderHistory} summary={true}></PrintTable>
    </Flex>
  );
}

export default PrintSummary;
