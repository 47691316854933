import React, { useContext } from "react";
import { TakeoutContext } from "../../../context/TakeoutContext";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";

export default function DeliveryModal({
  onOpenDelivery,
  isOpenDelivery,
  onCloseDelivery,
}) {
  const cancelRef = React.useRef();
  const { setDeliveryFee, updateDeliveryFee, setUpdateDeliveryFee } =
    useContext(TakeoutContext);

  return (
    <Modal
      isOpen={isOpenDelivery}
      onClose={onCloseDelivery}
      isCentered
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="bold">
          Custom Deliver Fee
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="black"
              fontSize="1.2em"
              children="$"
            />
            <Input
              placeholder="Enter amount"
              onChange={(event) => setUpdateDeliveryFee(event.target.value)}
            />
          </InputGroup>
        </ModalBody>

        <ModalFooter>
          <Button
            ref={cancelRef}
            onClick={() => {
              setUpdateDeliveryFee(0);
              onCloseDelivery();
            }}
            colorScheme="red"
          >
            Cancel
          </Button>
          <Button
            colorScheme="green"
            ml={3}
            onClick={() => {
              setDeliveryFee(updateDeliveryFee);
              onCloseDelivery();
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
