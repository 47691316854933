import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";

import { useLocation } from "react-router-dom";
import { useRef } from "react";
import { BiCart } from "react-icons/bi";
import CartBody from "./CartBody";

function Cart() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  let location = useLocation();

  let isCheckout = false;

  if (location.pathname === "/checkout") {
    isCheckout = true;
  }

  return (
    <>
      <IconButton
        display={["flex", "flex", "flex", "none"]}
        color="secondary"
        aria-label="Dropdown Menu"
        variant="unstyled"
        fontSize="1.3rem"
        icon={<BiCart />}
        ref={btnRef}
        onClick={onOpen}
      />

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent mt="80px">
          <CartBody checkout={isCheckout}></CartBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Cart;
