import React from "react";
import "../../../App.css";
import {
  Flex,
  Table,
  Thead,
  Tbody,
  Text,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
export default function PrintTable({ orderHistory, title, summary }) {
  let grandSubtotal = 0;
  let grandGST = 0;
  let grandTotal = 0;
  let orderHistoryCopy = [...orderHistory].reverse();

  return (
    <TableContainer mt="1rem">
      <style type="text/css" media="print">
        {" @page { size: portrait; } "}
      </style>
      <Flex fontWeight="bold" ml="1.2rem" fontSize="lg">
        {title}
      </Flex>
      <Table
        variant="simple"
        colorScheme="black"
        className="table-size"
        __css={{ tableLayout: "fixed", width: "100%" }}
      >
        <Thead>
          <Tr>
            <Th color="black" textAlign="start !important">
              Name
            </Th>
            <Th color="black" textAlign="start !important">
              Phone
            </Th>
            <Th color="black" textAlign="start !important">
              Address
            </Th>
            <Th color="black" textAlign="start !important" isNumeric>
              Subtotal
            </Th>
            <Th color="black" textAlign="start !important" isNumeric>
              GST
            </Th>
            <Th color="black" textAlign="start !important" isNumeric>
              Total
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {orderHistoryCopy.map((order, i) => {
            grandSubtotal =
              grandSubtotal +
              order.orderPrice.subTotalDiscount +
              order.orderPrice.deliveryFee;
            grandGST += order.orderPrice.gst;
            grandTotal += order.orderPrice.total;

            return (
              <Tr key={"repo" + i}>
                <Td color="black" textAlign="start !important">
                  {order.method === "5"
                    ? order.name
                    : order.table === "" &&
                      order.name === "" &&
                      order.address === ""
                    ? "-"
                    : order.table !== ""
                    ? order.table
                    : order.name}
                </Td>
                <Td color="black" textAlign="start !important">
                  {order.phone !== "" ? order.phone : "-"}
                </Td>
                <Td
                  color="black"
                  textAlign="start !important"
                  whiteSpace="normal"
                >
                  {order.address !== "" ? order.address : "-"}
                </Td>
                <Td color="black" textAlign="start !important">
                  $
                  {(
                    order.orderPrice.subTotalDiscount +
                    order.orderPrice.deliveryFee
                  ).toFixed(2)}
                </Td>
                <Td color="black" textAlign="start !important">
                  ${order.orderPrice.gst.toFixed(2)}
                </Td>
                <Td color="black" textAlign="start !important">
                  ${order.orderPrice.total.toFixed(2)}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex bg="#E5E4E2" pt="5px" pb="5px" justify="flex-end">
        <Text fontWeight="bold" mr="1rem">
          Subtotal: ${grandSubtotal.toFixed(2)}
        </Text>
        <Text fontWeight="bold" mr="1rem">
          GST: ${grandGST.toFixed(2)}
        </Text>
        <Text fontWeight="bold" mr="1rem">
          Total: ${grandTotal.toFixed(2)}
        </Text>
      </Flex>
    </TableContainer>
  );
}
