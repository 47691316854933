import React, { useState } from "react";

import { Flex } from "@chakra-ui/react";

import Navbar from "../components/main-page/Navbar";
import CartBody from "../components/order-page/CartBody";
import Form from "../components/order-page/Form";
import Loading from "../components/order-page/Loading";

function Checkout(props) {
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  return (
    <Flex width="100%" justify="center" direction="column" align="center">
      <Flex direction="column" width={{ base: "100%", "3xl": "1500px" }}>
        <Flex direction="column" align="center">
          <Navbar takeoutmode={true}></Navbar>
        </Flex>
      </Flex>
      <Flex
        justify="center"
        mt="90px"
        width={{ base: "100%", "3xl": "1500px" }}
      >
        <Flex width={{ base: "90%", "3xl": "100%" }} direction="column">
          <Flex mt="4rem">
            <Flex
              direction="column"
              width={{ base: "100%", lg: "73%" }}
              fontFamily="inner"
            >
              {loading ? (
                <Loading confirm={confirm}></Loading>
              ) : (
                <>
                  <Flex
                    display={{ lg: "none" }}
                    mb="3rem"
                    mt="3rem"
                    border="1px"
                  >
                    <CartBody summary={true} checkout={true}></CartBody>
                  </Flex>

                  <Form
                    getLoading={(loading) => setLoading(loading)}
                    getConfirm={(confirm) => setConfirm(confirm)}
                  ></Form>
                </>
              )}
            </Flex>

            <Flex
              direction="column"
              flex={1}
              display={{ base: "none", lg: "flex" }}
            >
              <CartBody checkout={true}></CartBody>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Checkout;
