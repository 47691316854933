import {
  Flex,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

function ComboForOne(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex
      justify="center"
      align="center"
      position="relative"
      width="100%"
      height="100%"
      borderWidth={{ base: "5px", sm: "10px", md: "15px" }}
      borderColor="brand.black"
      bg="brand.black"
    >
      <Flex
        justify="center"
        width="100%"
        height="100%"
        borderWidth="30px"
        borderColor="brand.beige"
        bg="brand.black"
        color="brand.beige"
        fontFamily="glory"
        fontSize={{ base: "subheader", sm: "main" }}
        textAlign={{ base: "center", sm: "start" }}
        sx={{
          borderImage: "url(/border.png)",
          borderImageSource: "url(/border.png)",
          borderImageSlice: "70",
          borderImageRepeat: "stretch",
          borderImageWidth: { base: "30px", sm: "40px", md: "50px" },
        }}
      >
        <Flex position="absolute" height="100%" top="100px">
          <Image
            src="/combobg4.png"
            objectFit="contain"
            objectPosition={{ base: "center", sm: "top" }}
            width="100%"
            height="100%"
            zIndex={1}
          ></Image>
        </Flex>
        <Flex
          direction="column"
          justify="center"
          align="center"
          width="100%"
          height="100%"
          pt="150px"
          pb="250px"
          pl={{ base: "0px", sm: "20px" }}
          pr={{ base: "0px", sm: "20px" }}
          fontSize={{ base: "mobile.main", sm: "main" }}
          textAlign="center"
          lineHeight={1}
        >
          <Text
            fontFamily="langar"
            fontSize={{ base: "mobile.header", sm: "header" }}
            letterSpacing={{ base: 0, sm: -0.5 }}
          >
            <Text as="span" fontSize={{ base: "32px", sm: "40px" }}>
              C
            </Text>
            ombo{" "}
            <Text as="span" fontSize={{ base: "32px", sm: "40px" }}>
              M
            </Text>
            enu
          </Text>
          <Text
            fontFamily="glory"
            fontSize={{ base: "mobile.chineseLetter", sm: "chineseLetter" }}
            fontWeight="bold"
            letterSpacing="3.2px"
            mt="10px"
            mb="40px"
          >
            一人套餐
          </Text>
          <Text
            mb={{ base: "5px", md: "0" }}
            maxWidth={{ base: "initial", sm: "350px", md: "initial" }}
            lineHeight={1.25}
          >
            <b>
              <i>Combo for One </i>
            </b>
            consists of a curated menu with multiple items to choose.
          </Text>
          <Text
            maxWidth={{ base: "initial", sm: "350px", md: "initial" }}
            lineHeight={1.25}
          >
            Available for dine-in, take-out and delivery during open hours.
          </Text>

          <Flex
            justify="center"
            align="center"
            width="fit-content"
            mt="50px"
            bg="brand.black"
          >
            <Image
              src="/combofor1.png"
              width="100%"
              height="100%"
              maxWidth={{ base: "500px", md: "650px", lg: "750px" }}
              zIndex={1}
              cursor="pointer"
              boxShadow="0px 0px 10px 0px #06141E"
              onClick={onOpen}
            ></Image>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
              <ModalOverlay />
              <ModalContent width="100%" maxWidth="775px" m="0">
                <ModalBody p="0px" bg="brand.beige">
                  <Image
                    src="/combofor1.png"
                    width="100%"
                    height="100%"
                    onClick={onClose}
                  ></Image>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ComboForOne;
