import React from "react";
import { Button, Text } from "@chakra-ui/react";
function ItemSelectionFront({
  selection,
  pickedItems,
  setPickedItems,
  trigger,
  setTrigger,
  choices,
}) {
  const handleComboDinner = (name, chineseName, code) => {
    let nameObj = { name: name, chineseName: chineseName, code: code };
    let tempPickedItems = pickedItems;
    tempPickedItems.push(nameObj);

    setPickedItems(tempPickedItems);
  };

  return (
    <Button
      colorScheme="blue"
      variant="outline"
      isDisabled={pickedItems.length.toString() === choices ? true : false}
      onClick={() => {
        handleComboDinner(
          selection.name,
          selection.chineseName,
          selection.code
        );
        setTrigger(!trigger);
      }}
      justify="flex-start"
    >
      <Text
        fontWeight="bold"
        color="#111111"
        width="100%"
        whiteSpace="wrap"
        fontSize={{ base: "12px", md: "14px" }}
      >
        {selection.code}: {selection.name}
      </Text>
    </Button>
  );
}

export default ItemSelectionFront;
