import React from "react";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  Flex,
  Text,
  Image,
  Link as ChakraLink,
} from "@chakra-ui/react";

import { ChevronRightIcon } from "@chakra-ui/icons";

function HoverMenu(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  return (
    <Popover
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
      onFocus={onOpen}
      onBlur={onClose}
      trigger="hover"
      closeDelay={0}
      openDelay={0}
      offset={[-75, 7]}
      gutter={50}
    >
      <PopoverTrigger
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        onFocus={onOpen}
        onBlur={onClose}
      >
        <Flex
          display={["none", "none", "flex", "flex"]}
          fontFamily="glory"
          ref={btnRef}
          onClick={onOpen}
          _hover={{ color: "brand.darkred" }}
        >
          Delivery
        </Flex>
      </PopoverTrigger>

      {/*https://github.com/chakra-ui/chakra-ui/issues/4665*/}
      <PopoverContent
        width="250px"
        bg="brand.beige2"
        border="none"
        borderRadius="5px"
        _after={{
          content: "''",
          position: "absolute",
          w: "100%",
          h: `${16}px`,
          top: `-${16}px`,
          left: 0,
        }}
      >
        <PopoverBody p="30px">
          <Flex direction="column">
            <Flex
              href="https://www.skipthedishes.com/kens-chinese-restaurant-2118"
              as={ChakraLink}
              isExternal
              direction="row"
              justify="space-between"
              _hover={{ color: "brand.darkred" }}
            >
              <Image
                src="/skipthedish.png"
                alt="SkipTheDishes"
                width={30}
                height={30}
              />
              <Text color="primary" mr="auto" ml="10px">
                SkipTheDishes
              </Text>
              <ChevronRightIcon
                color="primary"
                alignSelf="center"
                ml="8px"
                h={5}
                w={5}
              />
            </Flex>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default HoverMenu;
