import React, { useContext } from "react";
import { TakeoutContext } from "../../../context/TakeoutContext";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";

export default function ChargeModal({
  onOpenCharge,
  isOpenCharge,
  onCloseCharge,
}) {
  const {
    addCharges,
    setAddCharges,
    setShowCharge,
    updateToggle,
    setUpdateToggle,
  } = useContext(TakeoutContext);

  const updatePrice = () => {
    setUpdateToggle(!updateToggle);
  };

  return (
    <Modal
      isOpen={isOpenCharge}
      onClose={onCloseCharge}
      isCentered
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add / Minus Charges</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="black"
              fontSize="1.2em"
              children="$"
            />
            <Input
              placeholder="Enter amount"
              onChange={(event) => setAddCharges(event.target.value)}
            />
          </InputGroup>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              setAddCharges(0);
              onCloseCharge();
            }}
          >
            Close
          </Button>
          <Button
            colorScheme="green"
            onClick={() => {
              setShowCharge(parseFloat(addCharges));
              updatePrice();
              onCloseCharge();
            }}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
