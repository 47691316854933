import React, { useContext } from "react";
import { CartContext } from "../../context/CartContext";
import { Flex, Divider, Text, Button, Icon } from "@chakra-ui/react";
import { BsXCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

function CartBody(props) {
  const { cart, setCart, setDiscount, subTotal, setSubTotal, discountItems } =
    useContext(CartContext);

  const removeItem = (itemIndex) => {
    //removes item from cart
    setCart(cart.filter((item, i) => itemIndex !== i));

    let removedItemPrice = 0;

    if (cart[itemIndex].soupDiscountFlag) {
      removedItemPrice = cart[itemIndex].soupDiscountPrice;
    } else {
      removedItemPrice = cart[itemIndex].price * cart[itemIndex].quantity;
    }

    //subtract the price of item with the total
    if (
      parseFloat(subTotal) - parseFloat(removedItemPrice) <
      parseFloat(40.0)
    ) {
      setDiscount((prevState) => ({
        ...prevState,
        percentage: 0,
      }));
    }

    setSubTotal(
      (parseFloat(subTotal) - parseFloat(removedItemPrice)).toFixed(2)
    );
  };

  function RoundNum(num, length) {
    var number = Math.round(num * Math.pow(10, length)) / Math.pow(10, length);
    return number;
  }

  const getTotal = (subTotal) => {
    if (parseFloat(subTotal) >= 40.0) {
      let tempSubTotal = parseFloat(subTotal);
      let discount = tempSubTotal * 0.05;
      let subTotalWithDisc = tempSubTotal - discount;
      let gst = RoundNum(subTotalWithDisc * 0.05, 2);
      return RoundNum(subTotalWithDisc + gst, 2);
    } else {
      return RoundNum(parseFloat(subTotal) + parseFloat(subTotal) * 0.05, 2);
    }
  };

  const getGST = (subTotal) => {
    let tempSubTotal = parseFloat(subTotal);
    //apply discount
    if (parseFloat(subTotal) >= 40.0) {
      let discount = tempSubTotal * 0.05;
      let subTotalWithDisc = tempSubTotal - discount;
      return RoundNum(subTotalWithDisc * 0.05, 2);
    } else {
      return RoundNum(tempSubTotal * 0.05, 2);
    }
  };

  return (
    <Flex
      direction="column"
      border={{ base: "0px", lg: "1px" }}
      borderRadius="3px"
      borderColor={{ base: "none", lg: "#c4c4c4" }}
      minHeight={{ base: "100%", md: "50vh" }}
      overflowY={!props.summary ? "auto" : "none"}
      position={!props.summary ? "sticky" : "none"}
      top={!props.summary ? "12rem" : "0rem"}
      mt={{ base: "0rem", md: "3rem" }}
      mb="2rem"
      width="100%"
      zIndex={0}
    >
      <Flex
        width="100%"
        justify="center"
        mt="1rem"
        mb="1rem"
        fontFamily="body"
        fontWeight={700}
        fontSize={24}
        align="center"
        textAlign="center"
      >
        {props.checkout ? "Order Summary" : "Your Pickup Order"}
      </Flex>
      <Divider borderColor="#c4c4c4"></Divider>

      <Flex
        direction="column"
        height="100%"
        width="100%"
        justify="space-between"
      >
        <Flex direction="column">
          {cart &&
            cart.map((item, i) => {
              return (
                <Flex
                  borderBottom="1px"
                  borderColor="#c4c4c4"
                  borderRadius="3px"
                  key={"pickup-item: " + i}
                  pt="1rem"
                  pb="1rem"
                  justify="space-around"
                  fontSize="15px"
                >
                  <Flex fontWeight={700} flex={1} justify="center">
                    <Text>{item.quantity + "x"}</Text>
                  </Flex>
                  <Flex
                    direction="column"
                    width={props.checkout ? "80%" : "68%"}
                  >
                    <Flex
                      justify="space-between"
                      mr={props.checkout ? "1rem" : "0rem"}
                    >
                      <Text fontWeight={700} mr="0.6rem">
                        {item.number
                          ? item.number + ". " + item.name
                          : item.name}{" "}
                      </Text>
                      {item.soupDiscountFlag ? (
                        //discounted soup price
                        <Text>${item.soupDiscountPrice.toFixed(2)}</Text>
                      ) : (
                        <Text>${(item.price * item.quantity).toFixed(2)}</Text>
                      )}
                    </Flex>

                    <Flex direction="column">
                      {item.comboPickedItems.length !== 0
                        ? item.comboPickedItems.map((comboSelection, i) => {
                            return (
                              <Text key={"cbae" + i} fontSize="menusm">
                                - {comboSelection.code}: {comboSelection.name}
                              </Text>
                            );
                          })
                        : null}
                    </Flex>
                    <Flex>
                      {item.soupDiscountFlag ? (
                        <Text fontSize="14px" color="red" as={"i"}>
                          -Discount Applied (20%)
                        </Text>
                      ) : null}
                    </Flex>
                    <Flex>
                      {item.note ? (
                        <Text fontSize="14px">Note: {item.note}</Text>
                      ) : null}
                    </Flex>
                  </Flex>
                  <Flex
                    flex={1}
                    justify="center"
                    display={props.checkout ? "none" : "flex"}
                  >
                    <Icon
                      mt="2px"
                      _hover={{ cursor: "pointer", opacity: "70%" }}
                      w={4}
                      h={4}
                      as={BsXCircleFill}
                      onClick={() => removeItem(i)}
                    />
                  </Flex>
                </Flex>
              );
            })}
        </Flex>

        <Flex direction="column" m={3}>
          <Flex
            mt="1rem"
            mb={props.checkout ? "0rem" : "1rem"}
            justify="space-between"
          >
            <Flex direction="column">
              <Text fontSize="20px" fontWeight={700}>
                Subtotal
              </Text>
              <Text color="#707070" fontSize="14px" lineHeight="0.5">
                {props.checkout ? null : "(without tax)"}
              </Text>
            </Flex>
            <Text fontSize="20px" fontWeight={700}>
              {"$" + parseFloat(subTotal).toFixed(2)}
            </Text>
          </Flex>

          {props.checkout ? (
            <>
              <Flex direction="column" align="flex-start">
                {parseFloat(subTotal) >= 40.0 ? (
                  <Flex
                    justify="space-between"
                    width="100%"
                    fontSize="20px"
                    fontWeight={700}
                  >
                    <Text>Discount</Text>
                    <Text>${RoundNum(subTotal * 0.05, 2).toFixed(2)}</Text>
                  </Flex>
                ) : null}
                {parseFloat(subTotal) >= 40.0 ? (
                  <Flex
                    justify="space-between"
                    width="100%"
                    fontSize="20px"
                    fontWeight={700}
                  >
                    <Text>Subtotal w/ Discount</Text>
                    <Text>
                      ${RoundNum(subTotal - subTotal * 0.05, 2).toFixed(2)}
                    </Text>
                  </Flex>
                ) : null}
                <Flex
                  justify="space-between"
                  width="100%"
                  fontSize="20px"
                  fontWeight={700}
                >
                  <Text>GST</Text>
                  <Text>
                    <Text>{"$" + getGST(subTotal).toFixed(2)}</Text>
                  </Text>
                </Flex>
                <Flex
                  justify="space-between"
                  width="100%"
                  fontSize="20px"
                  fontWeight={700}
                >
                  <Text mb="0.5rem">Total</Text>
                  <Text>{"$" + getTotal(subTotal).toFixed(2)}</Text>
                </Flex>
              </Flex>
            </>
          ) : null}

          {props.checkout ? null : (
            <Link to="/checkout">
              <Button
                width="100%"
                mt="0.7rem"
                mb={{ base: "1rem", md: "0.7rem" }}
                height={{ base: "50px", md: "54px" }}
                borderRadius="3px"
                bg="black"
                color="white"
                _hover={{ opacity: "70%" }}
                isDisabled={cart.length === 0 ? true : false}
              >
                Checkout
              </Button>
            </Link>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CartBody;
