import React from "react";

import { Flex, Text, Image, SimpleGrid } from "@chakra-ui/react";

function Catering(props) {
  const areas = [
    "Ogden",
    "Lynnwood",
    "Millican",
    "Riverbend",
    "Quarry Park",
    "Douglasglen",
    "Dover",
    "Erin Woods",
    "Douglasdale",
    "McKenzie",
    "Copperfield",
    "New Brighton",
  ];
  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      justify="center"
      align="center"
      width="100%"
      bg="brand.beige"
      pt={{ base: "150px", sm: "150px" }}
      pb={{ base: "150px", sm: "150px" }}
      pl={{ base: "10px", sm: "0" }}
      pr={{ base: "10px", sm: "0" }}
    >
      <Flex
        direction="column"
        align="center"
        width={{ base: "100%", lg: "40%" }}
        mr={{ base: "0", lg: "50px" }}
        fontFamily="glory"
        fontSize={{ base: "mobile.main", sm: "main" }}
        textAlign="center"
        lineHeight={1}
      >
        <Flex direction="column" align="center" mb="40px">
          <Text
            fontFamily="langar"
            fontSize={{ base: "mobile.header", sm: "header" }}
            letterSpacing={{ base: 0, sm: -0.5 }}
          >
            <Text as="span" fontSize={{ base: "32px", sm: "40px" }}>
              D
            </Text>
            elivery &{" "}
            <Text as="span" fontSize={{ base: "32px", sm: "40px" }}>
              C
            </Text>
            atering
          </Text>
          <Text
            fontFamily="glory"
            fontSize={{ base: "mobile.chineseLetter", sm: "chineseLetter" }}
            fontWeight="bold"
            letterSpacing="3.2px"
            mt="10px"
          >
            送餐&聚会
          </Text>
        </Flex>

        <Text mb="15px">
          We provide delivery to these areas at your convenience:
        </Text>

        <Flex justify="center" width="100%" maxWidth="450px" mb="15px">
          <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} width="100%">
            {areas.map((place, i) => {
              return (
                <Text key={"da" + i} lineHeight={1.5}>
                  {place}
                </Text>
              );
            })}
          </SimpleGrid>
        </Flex>

        <Text maxWidth="425px" mt="15px">
          At Kowloon, we provide catering for any party size, big or small. Let
          us know the number of people and we'll ensure your event is filled
          with delicious food.
        </Text>

        <Text maxWidth="425px" mt="15px">
          You may also contact us at{" "}
          <a href="tel:+4032794471">
            <u>403-279-4471</u>
          </a>{" "}
          with any questions or requests!
        </Text>
      </Flex>
      <Flex height="100%" overflow="hidden">
        <Flex
          justify={{ base: "flex-start", sm: "center" }}
          align="center"
          height="100%"
          mt={{ base: "50px", lg: "0" }}
          overflowX={{ base: "scroll", sm: "hidden" }}
        >
          <Image
            src="/crunchychowmein.png"
            objectFit="cover"
            objectPosition="98% 2%"
            width={{ base: "150px", sm: "175px", xl: "225px" }}
            height={{ base: "275px", sm: "350px", xl: "450px" }}
            mr="20px"
            borderRadius="10px"
          ></Image>
          <Image
            src="/sweetsourpork.png"
            objectFit="cover"
            objectPosition="98% 2%"
            width={{ base: "100px", sm: "125px", xl: "150px" }}
            height={{ base: "275px", sm: "350px", xl: "450px" }}
            mr="20px"
            borderRadius="10px"
          ></Image>
          <Image
            src="/wontonsoup.png"
            objectFit="cover"
            objectPosition="98% 2%"
            width={{ base: "75px", sm: "100px", xl: "125px" }}
            height={{ base: "275px", sm: "350px", xl: "450px" }}
            borderRadius="10px"
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Catering;
