import React, { useContext } from "react";
import { useDisclosure, Flex, Text } from "@chakra-ui/react";

import { TakeoutContext } from "../../context/TakeoutContext";
import moment from "moment";
import TakeoutPopup from "./TakeoutPopup";
moment().format();

function ItemButton({ item }) {
  const {
    isOpen: isItemOpen,
    onOpen: onItemOpen,
    onClose: onItemClose,
  } = useDisclosure();

  return (
    <>
      <Flex
        border="1px"
        borderRadius="0px"
        borderColor="#c4c4c4"
        variant="outline"
        fontFamily="body"
        fontWeight={700}
        fontSize="15px"
        align="center"
        justify="flex-start"
        height="100%"
        width="100%"
        pt="1rem"
        pb="1rem"
        pr="1rem"
        pl="1rem"
        onClick={() => {
          onItemOpen();
        }}
        _hover={{
          boxShadow: "base",
          bg: "white",
          cursor: "pointer",
        }}
      >
        <Flex width="100%">
          <Flex mr="0.5rem">
            <Text>{item.number}. </Text>
          </Flex>
          <Flex direction="column">
            <Text>{item.name}</Text>
            <Text>{item.chineseName}</Text>
          </Flex>
          <Flex ml="auto">
            {item.special ? (
              <Text>
                ${item.prices.oneItem.price.toFixed(2)}/$
                {item.prices.twoItem.price.toFixed(2)}
              </Text>
            ) : (
              <Text>${item.price.toFixed(2)}</Text>
            )}
          </Flex>
        </Flex>
      </Flex>
      <TakeoutPopup
        item={item}
        isOpen={isItemOpen}
        onOpen={onItemOpen}
        onClose={onItemClose}
      ></TakeoutPopup>
    </>
  );
}

export default ItemButton;
