export const data = {
  menu: [
    {
      category: "Dinner Combo",
      items: [
        {
          number: 115,
          name: "Dinner for One",
          chineseName: "一人餐",
          special: true,
          selection: [
            { code: "Q1", name: "Ginger Beef", chineseName: "牛C" },
            { code: "Q2", name: "Sweet & Sour Pork", chineseName: "古肉" },
            {
              code: "Q3",
              name: "Lemon Chicken",
              chineseName: "柠檬鸡",
            },
            { code: "Q4", name: "Dry Garlic Spareribs", chineseName: "干骨" },
            {
              code: "Q5",
              name: "Chicken Balls",
              chineseName: "炸鸡波",
            },
            {
              code: "Q6",
              name: "Deep Fried Bubble Shrimp",
              chineseName: "炸虾球",
            },
            {
              code: "Q7",
              name: "Chicken Chop Suey",
              chineseName: "鸡杂碎",
            },
            {
              code: "Q8",
              name: "Chicken w/ Mixed Veggies",
              chineseName: "鸡杂菜",
            },
            {
              code: "Q9",
              name: "Stir Fried Mixed Veggies",
              chineseName: "炒杂菜",
            },
            {
              code: "",
              name: "",
              chineseName: "",
            },
          ],
          prices: {
            oneItem: { price: 12.69, toggle: false },
            twoItem: { price: 15.69, toggle: false },
            threeItem: { price: 5.69, toggle: false },
          },
        },
        {
          number: 116,
          name: "Dinner for Two",

          chineseName: "两人餐",
          combo: true,
          comboItems: [
            { name: "Spring Rolls (2)", chineseName: "春卷 (2)" },
            { name: "Wonton Soup", chineseName: "云吞汤" },
            { name: "Chicken Fried Rice", chineseName: "鸡饭" },
            { name: "Ginger Beef", chineseName: "牛C" },
            { name: "Chicken Mixed Vegetables", chineseName: "鸡杂菜" },
          ],
          price: 39.96,
        },
        {
          number: 117,
          name: "Dinner for Three",
          chineseName: "三人餐",
          combo: true,
          comboItems: [
            { name: "Spring Rolls (3)", chineseName: "春卷 (3)" },
            { name: "Wonton Soup", chineseName: "云吞汤" },
            { name: "Chicken Fried Rice", chineseName: "鸡炒饭" },
            { name: "Ginger Beef", chineseName: "牛C" },
            { name: "Chicken Mixed Vegetables", chineseName: "鸡杂菜" },
            { name: "BBQ Pork Shanghai Noodle", chineseName: "叉烧粗面" },
          ],
          price: 53.96,
        },
        {
          number: 118,
          name: "Dinner for Four",
          chineseName: "四人餐",
          combo: true,
          comboItems: [
            { name: "Spring Rolls (4)", chineseName: "春卷 (4)" },
            { name: "Wonton Soup", chineseName: "云吞汤" },
            { name: "Chicken Fried Rice", chineseName: "鸡炒饭" },
            { name: "Ginger Beef", chineseName: "牛C" },
            { name: "Chicken Mixed Vegetables", chineseName: "鸡杂菜" },
            { name: "Sweet & Sour Pork", chineseName: "古肉" },
            { name: "Cantonese Chow Mein", chineseName: "广东面" },
          ],
          price: 78.96,
        },
        {
          number: 119,
          name: "Dinner for Six",
          chineseName: "六人餐",
          combo: true,
          comboItems: [
            { name: "Spring Rolls (6)", chineseName: "春卷 (6)" },
            { name: "Wonton Soup", chineseName: "云吞汤" },
            { name: "Chicken Fried Rice", chineseName: "鸡炒饭" },
            { name: "Ginger Beef", chineseName: "牛C" },
            { name: "Chicken Mixed Vegetables", chineseName: "鸡杂菜" },
            { name: "Sweet & Sour Chicken", chineseName: "古鸡" },
            { name: "Cantonese Chow Mein", chineseName: "广东面" },
            { name: "Cashew Nut Gai Ding", chineseName: "腰果鸡丁" },
            { name: "Deep Fried Bubble Shrimp", chineseName: "炸虾球" },
          ],
          price: 109.96,
        },
      ],
    },
    {
      category: "Appetizers",
      items: [
        {
          number: "A1",
          name: "Spring Rolls (2)",
          chineseName: "春卷 (2)",
          price: 3.96,
        },
        {
          number: "A2",
          name: "Meat Spring Rolls (2)",
          chineseName: "肉卷 (2)",
          price: 4.96,
        },
        {
          number: "A3",
          name: " Egg Roll (1)",
          chineseName: "蛋卷 (1)",
          price: 3.96,
        },
        {
          number: "A4",
          name: "Deep Fried Wonton",
          chineseName: "炸云吞",
          price: 11.96,
        },
        {
          number: "A5",
          name: "Pork & Veggie Dumplings (10)",
          chineseName: "锅贴 (10)",
          price: 11.96,
        },
        {
          number: "A6",
          name: "BBQ Pork",
          chineseName: "蜜汁叉燒",
          price: 15.96,
        },
        {
          number: "A7",
          name: "Dry Garlic Spareribs",
          chineseName: "干骨",
          price: 12.96,
        },
        {
          number: "A8",
          name: "Deep Fried Chicken Wings",
          chineseName: "炸鸡翼",
          price: 12.96,
        },
        {
          number: "A9",
          name: "Salt & Pepper Chicken Wings",
          chineseName: "椒鸡翼",
          price: 12.96,
          spicy: true,
        },
        {
          number: "A10",
          name: "Honey Garlic Chicken Wings",
          chineseName: "蜜汁鸡翼",
          price: 12.96,
        },
      ],
    },
    {
      category: "Soup",
      items: [
        {
          number: "U1",
          name: "Pork Wonton Soup (16oz, 5pc)",
          chineseName: "猪云",
          price: 5.96,
          sizes: false,
        },
        {
          number: "U2",
          name: "Chicken Wonton Soup (16oz, 5pc)",
          chineseName: "鸡云",
          price: 5.96,
          sizes: false,
        },
        {
          number: "U3",
          name: "Wor Wonton Soup (24oz, 5pc)",
          chineseName: "窝云",
          price: 8.96,
          sizes: false,
        },
        {
          number: "U4",
          name: "Hot & Sour Soup (24oz)",
          chineseName: "酸辣汤",
          price: 8.96,
          sizes: false,
          spicy: true,
        },
        {
          number: "U5",
          name: "Chicken Corn Soup (24oz)",
          chineseName: "鸡茸粟米羹",
          price: 8.96,

          sizes: false,
        },
      ],
    },
    {
      category: "Pork",
      items: [
        {
          number: "K1",
          name: "Sweet & Sour Pork",
          chineseName: "古肉",
          price: 13.96,
        },

        {
          number: "K2",
          name: "Sweet & Sour Spareribs",
          chineseName: "古骨",
          price: 13.96,
        },
        {
          number: "K3",
          name: "Honey Garlic Spareribs",
          chineseName: "蜜汁骨",
          price: 13.96,
        },
        {
          number: "K4",
          name: "Salt & Pepper Pork",
          chineseName: " 椒猪",
          price: 13.96,
          spicy: true,
        },
        {
          number: "K5",
          name: "Salt & Pepper Spareribs",
          chineseName: "椒骨",
          price: 13.96,
          spicy: true,
        },
        {
          number: "K6",
          name: "BBQ Pork w/ Mixed Veggies",
          chineseName: "叉烧杂菜",
          price: 13.96,
        },
        {
          number: "K7",
          name: "BBQ Pork w/ Broccoli",
          chineseName: "叉烧百加利",
          price: 13.96,
        },
      ],
    },
    {
      category: "Beef",
      items: [
        {
          number: "B1",
          name: "Ginger Beef",
          chineseName: "牛C",
          price: 14.96,
          spicy: true,
        },
        {
          number: "B2",
          name: "Beef w/ Mixed Veggies",
          chineseName: "牛杂菜",
          price: 14.96,
        },

        {
          number: "B3",
          name: "Beef w/ Broccoli",
          chineseName: "百加利牛",
          price: 14.96,
        },
        {
          number: "B4",
          name: "Beef w/ Mushroom & Baby Corn",
          chineseName: "蘑菇粟米牛",
          price: 14.96,
        },
        {
          number: "B5",
          name: "Beef w/ 2 Kinds of Mushroom",
          chineseName: "双菇牛",
          price: 14.96,
        },
        {
          number: "B6",
          name: "Beef w/ Black Bean Sauce",
          chineseName: "豉汁牛",
          price: 14.96,
        },
        {
          number: "B7",
          name: "Beef w/ Satay Sauce",
          chineseName: "沙爹牛",
          price: 14.96,
          spicy: true,
        },
        {
          number: "B8",
          name: "Beef w/ Black Pepper Sauce",
          chineseName: "黑椒牛",
          price: 14.96,
          spicy: true,
        },
        {
          number: "B9",
          name: "Beef w/ Szechuan Sauce",
          chineseName: "四川牛",
          price: 14.96,
          spicy: true,
        },
        {
          number: "B10",
          name: "Beef Curry",
          chineseName: "咖喱牛",
          price: 14.96,
          spicy: true,
        },
      ],
    },
    {
      category: "Chicken",

      items: [
        {
          number: "C1",
          name: "Sweet & Sour Chicken",
          chineseName: "古鸡",
          price: 13.96,
        },
        {
          number: "C2",
          name: "Ginger Chicken",
          chineseName: "鸡C",
          price: 13.96,
          spicy: true,
        },
        {
          number: "C3",
          name: "Lemon Chicken",
          chineseName: "柠檬鸡",
          price: 13.96,
        },
        {
          number: "C4",
          name: "Sesame Chicken",
          chineseName: "芝麻鸡",
          price: 13.96,
        },
        {
          number: "C5",
          name: "Palace Style Chicken",
          chineseName: "宫保鸡",
          price: 13.96,
          spicy: true,
        },
        {
          number: "C6",
          name: "Honey Garlic Chicken",
          chineseName: "蜜汁鸡",
          price: 13.96,
        },
        {
          number: "C7",
          name: "Salt & Pepper Chicken",
          chineseName: "椒鸡C",
          price: 13.96,
          spicy: true,
        },
        {
          number: "C8",
          name: "Chicken Balls",
          chineseName: "炸鸡波",
          price: 13.96,
        },
        {
          number: "C9",
          name: "Lemon Soo Gai",
          chineseName: "柠檬酥鸡",
          price: 13.96,
        },
        {
          number: "C10",
          name: "Almond Soo Gai",
          chineseName: "杏仁酥鸡",
          price: 13.96,
        },

        {
          number: "C11",
          name: "Chicken w/ Mixed Vegetables",
          chineseName: "鸡杂菜",
          price: 13.96,
        },
        {
          number: "C12",
          name: "Chicken w/ Broccoli",
          chineseName: "百加利鸡",
          price: 13.96,
        },
        {
          number: "C13",
          name: "Chicken w/ Mushroom & Baby Corn",
          chineseName: "蘑菇粟米鸡",
          price: 13.96,
        },
        {
          number: "C14",
          name: "Chicken w/ 2 Kinds of Mushroom",
          chineseName: "双菇鸡",
          price: 13.96,
        },
        {
          number: "C15",

          name: "Almond Gai Ding",
          chineseName: "杏仁鸡丁",
          price: 13.96,
        },
        {
          number: "C16",
          name: "Cashew Gai Ding",
          chineseName: "腰果鸡丁",
          price: 13.96,
        },
        {
          number: "C17",
          name: "Chicken w/ Black Bean Sauce",
          chineseName: "豉汁鸡",
          price: 13.96,
        },
        {
          number: "C18",
          name: "Chicken w/ Satay Sauce",
          chineseName: "沙爹鸡",
          price: 13.96,
        },
        {
          number: "C19",
          name: "Chicken w/ Black Pepper Sauce",
          chineseName: "黑椒鸡",
          price: 13.96,
          spicy: true,
        },
        {
          number: "C20",
          name: "Chicken w/ Szechuan Sauce",
          chineseName: "四川鸡",
          price: 13.96,
          spicy: true,
        },
        {
          number: "C21",
          name: "Chicken Curry",
          chineseName: "咖喱鸡",
          price: 13.96,
          spicy: true,
        },
      ],
    },
    {
      category: "Seafood",
      items: [
        {
          number: "S1",
          name: "Deep Fried Bubble Shrimp",
          chineseName: "炸虾球",
          price: 16.96,
        },
        {
          number: "S2",
          name: "Salt & Pepper Seafood",
          chineseName: "椒三鲜",
          price: 17.96,
        },
        {
          number: "S3",
          name: "Salt & Pepper Squid",
          chineseName: "椒鱿",
          price: 16.96,
          spicy: true,
        },
        {
          number: "S4",
          name: "Salt & Pepper Fish",
          chineseName: "椒鱼",
          price: 16.96,
          spicy: true,
        },
        {
          number: "S5",
          name: "Salt & Pepper Shrimp",
          chineseName: "椒虾",
          price: 16.96,
          spicy: true,
        },
        {
          number: "S6",
          name: "Sesame Shrimp",
          chineseName: "芝麻虾",
          price: 16.96,
        },
        {
          number: "S7",
          name: "Palace Style Shrimp",
          chineseName: "宫保虾",
          price: 16.96,
          spicy: true,
        },
        {
          number: "S8",
          name: "Shrimp w/ Mixed Vegetables",
          chineseName: "虾杂菜",
          price: 16.96,
        },
        {
          number: "S9",
          name: "Shrimp w/ Broccoli",
          chineseName: "百加利虾",
          price: 16.96,
        },
        {
          number: "S10",
          name: "Shrimp w/ Mushroom & Baby Corn",

          chineseName: "蘑菇粟米虾",
          price: 16.96,
        },
        {
          number: "S11",
          name: "Shrimp w/ 2 Kinds of Mushroom",
          chineseName: "双菇虾",
          price: 16.96,
        },
        {
          number: "S12",
          name: "Cashew w/ Shrimp & Diced Veggies",
          chineseName: "腰果虾丁",
          price: 16.96,
        },
        {
          number: "S13",
          name: "Shrimp w/ Black Bean Sauce",
          chineseName: "豉汁虾",
          price: 16.96,
        },
        {
          number: "S14",
          name: "Shrimp w/ Satay Sauce",
          chineseName: "沙爹虾",
          price: 16.96,
          spicy: true,
        },
        {
          number: "S15",
          name: "Shrimp w/ Black Pepper Sauce",
          chineseName: "黑椒虾",
          price: 16.96,
          spicy: true,
        },
        {
          number: "S16",
          name: "Shrimp w/ Szechuan Sauce",
          chineseName: "四川虾",
          price: 16.96,
          spicy: true,
        },
        {
          number: "S17",
          name: "Shrimp Curry",
          chineseName: "咖喱虾",
          price: 16.96,
          spicy: true,
        },
      ],
    },
    {
      category: "Vegetables",
      items: [
        {
          number: "V1",
          name: "Stir-Fried Mixed Veggies",
          chineseName: "炒杂菜",
          price: 13.96,
        },
        {
          number: "V2",
          name: "Stir-Fried 4 Kinds of Veggies",
          desc: "(Snow Pea, Baby Corn, Mushroom, Broccoli)",
          chineseName: "炒四蔬",
          price: 13.96,
        },
        {
          number: "V3",
          name: "Stir-Fried Mixed Veggies w/ Tofu",
          chineseName: "豆腐杂菜",
          price: 14.96,
        },
        {
          number: "V4",
          name: "Salt & Pepper Tofu",
          chineseName: "椒豆腐",
          price: 13.96,
          spicy: true,
        },
        {
          number: "V5",
          name: "Mixed Veggies w/ Black Bean Sauce",
          chineseName: "豉汁杂菜",
          price: 13.96,
        },
        {
          number: "V6",
          name: "Mixed Veggies w/ Satay Sauce",
          chineseName: "沙爹杂菜",
          price: 13.96,
          spicy: true,
        },
        {
          number: "V7",
          name: "Mixed Veggies w/ Black Pepper",

          chineseName: "黑椒杂菜",
          price: 13.96,
          spicy: true,
        },
        {
          number: "V8",
          name: "Mixed Veggies w/ Szechuan Sauce",
          chineseName: "四川杂菜",
          price: 13.96,
          spicy: true,
        },
        {
          number: "V9",
          name: "Mixed Veggies w/ Curry Sauce",

          chineseName: "咖喱杂菜",
          price: 13.96,
          spicy: true,
        },
        {
          number: "V10",
          name: "Mapo Tofu",
          chineseName: "麻婆豆腐",
          price: 13.96,
          spicy: true,
        },
      ],
    },
    {
      category: "Chop Suey",
      items: [
        {
          number: "Y1",
          name: "House Special Chop Suey",
          desc: "(Chicken, BBQ Pork, Shrimp)",
          chineseName: "招牌杂碎",
          price: 14.96,
        },
        {
          number: "Y2",
          name: "BBQ Pork Chop Suey",
          chineseName: "叉烧杂碎",
          price: 13.96,
        },
        {
          number: "Y3",
          name: "Beef Chop Suey",
          chineseName: "牛杂碎",
          price: 13.96,
        },
        {
          number: "Y4",
          name: "Chicken Chop Suey",
          chineseName: "鸡杂碎",
          price: 13.96,
        },
        {
          number: "Y5",
          name: "Shrimp Chop Suey",
          chineseName: "虾杂碎",
          price: 14.96,
        },
        {
          number: "Y6",
          name: "Mushroom Chop Suey",
          chineseName: "蘑菇杂碎",
          price: 13.96,
        },
      ],
    },
    {
      category: "Egg Foo Yung",
      items: [
        {
          number: "F1",
          name: "House Special Foo Yung",
          desc: "(Chicken, BBQ Pork, Shrimp)",

          chineseName: "招牌芙蓉",
          price: 15.96,
        },
        {
          number: "F2",
          name: "BBQ Pork Foo Yung",
          chineseName: "叉烧芙蓉",
          price: 14.96,
        },
        {
          number: "F3",
          name: "Chicken Foo Yung",
          chineseName: "鸡芙蓉",
          price: 14.96,
        },
        {
          number: "F4",
          name: "Shrimp Foo Yung",
          chineseName: "虾芙蓉",
          price: 15.96,
        },
        {
          number: "F5",
          name: "Mushroom Foo Yung",
          chineseName: "蘑菇芙蓉",
          price: 14.96,
        },
        {
          number: "F6",
          name: "Plain Egg Foo Yung",
          chineseName: "斋芙蓉",
          price: 14.96,
        },
      ],
    },
    {
      category: "Chow Mein (Thin)",
      items: [
        {
          number: "M1",
          name: "House Special Chow Mein (Crispy)",
          desc: "(Chicken, BBQ Pork, Shrimp)",
          chineseName: "广东面",
          price: 14.96,
        },
        {
          number: "M2",
          name: "BBQ Pork Chow Mein",
          chineseName: "叉烧幼面",
          price: 13.96,
        },
        {
          number: "M3",
          name: "Beef Chow Mein",
          chineseName: "牛幼面",
          price: 13.96,
        },
        {
          number: "M4",
          name: "Chicken Chow Mein",
          chineseName: "鸡幼面",
          price: 13.96,
        },
        {
          number: "M5",
          name: "Shrimp Chow Mein",
          chineseName: "虾幼面",
          price: 14.96,
        },
        {
          number: "M6",
          name: "Veggie Chow Mein",
          chineseName: "杂菜幼面",
          price: 13.96,
        },
        {
          number: "M7",
          name: "Classic Chow Mein w/ Bean Sprout",
          chineseName: "豉油王幼面",
          price: 13.96,
        },
        {
          number: "M8",
          name: "Beef Chow Mein w/ Black Bean Sauce",
          chineseName: "豉汁牛幼面",
          price: 13.96,
        },
        {
          number: "M9",
          name: "Beef Chow Mein w/ Satay Sauce",
          chineseName: "沙爹牛幼面",
          price: 13.96,
          spicy: true,
        },
        {
          number: "M10",
          name: "Beef Chow Mein w/",
          desc: "Black Pepper Sauce",
          chineseName: "黑椒牛幼面",
          price: 13.96,
          spicy: true,
        },
        {
          number: "M11",
          name: "Singapore Style Rice Noodle",
          chineseName: "星米",
          price: 14.96,
          spicy: true,
        },
        {
          number: "M12",
          name: "Home Style Rice Noodle",
          chineseName: "炒米粉",
          price: 14.96,
        },
      ],
    },
    {
      category: "Shanghai Noodles (Thick)",
      items: [
        {
          number: "H1",
          name: "House Special Shanghai Noodle",
          desc: "(Chicken, BBQ Pork, Shrimp)",
          chineseName: "招牌粗面",
          price: 14.96,
        },
        {
          number: "H2",
          name: "Shanghai Noodle w/ 3 Kinds of Meat",
          desc: "(Chicken, BBQ Pork, Beef)",
          chineseName: "三肉粗面",
          price: 14.96,
        },
        {
          number: "H3",
          name: "BBQ Pork Shanghai Noodle",
          chineseName: "叉烧粗面",
          price: 13.96,
        },
        {
          number: "H4",
          name: "Beef Shanghai Noodle",
          chineseName: "牛粗面",
          price: 13.96,
        },
        {
          number: "H5",
          name: "Chicken Shanghai Noodle",
          chineseName: "鸡粗面",
          price: 13.96,
        },
        {
          number: "H6",
          name: "Shrimp Shanghai Noodle",
          chineseName: "虾粗面",
          price: 14.96,
        },
        {
          number: "H7",
          name: "Plain Shanghai Noodle",
          chineseName: "斋粗面",
          price: 13.96,
        },
        {
          number: "H8",
          name: "BBQ Pork Shanghai Noodle w/",
          desc: "Szechuan Sauce",
          chineseName: "四川叉燒粗面",
          price: 13.96,
          spicy: true,
        },
        {
          number: "H9",
          name: "Beef Shanghai Noodle w/",

          desc: "Szechuan Sauce",
          chineseName: "四川牛粗面",
          price: 13.96,
          spicy: true,
        },
        {
          number: "H10",
          name: "Chicken Shanghai Noodle w/",
          desc: "Szechuan Sauce",
          chineseName: "四川鸡粗面",
          price: 13.96,
          spicy: true,
        },
        {
          number: "H11",
          name: "Beef Shanghai Noodle w/",
          desc: "Black Pepper Sauce",
          chineseName: "黑椒牛粗面",
          price: 13.96,
          spicy: true,
        },
        {
          number: "H12",
          name: "Chicken Shanghai Noodle w/",
          desc: "Black Pepper Sauce",
          chineseName: "黑椒鸡粗面",
          price: 13.96,
          spicy: true,
        },
      ],
    },
    {
      category: "Rice",
      items: [
        {
          number: "R1",
          name: "House Special Fried Rice",
          desc: "(Chicken, BBQ Pork, Shrimp)",
          chineseName: "土豪饭",
          price: 13.96,
        },
        {
          number: "R2",
          name: "BBQ Pork Fried Rice",
          chineseName: "叉烧饭",
          price: 12.96,
        },
        {
          number: "R3",
          name: "Beef Fried Rice",
          chineseName: "牛饭",
          price: 12.96,
        },
        {
          number: "R4",
          name: "Chicken Fried Rice",

          chineseName: "鸡饭",
          price: 12.96,
        },
        {
          number: "R5",
          name: "Shrimp Fried Rice",
          chineseName: "虾饭",
          price: 13.96,
        },
        {
          number: "R6",
          name: "Mixed Veggies Fried Rice",
          chineseName: "杂菜饭",
          price: 12.96,
        },
        {
          number: "R7",
          name: "Mushroom Fried Rice",
          chineseName: "蘑菇饭",
          price: 12.96,
        },
        {
          number: "R8",
          name: "Steamed Rice",
          chineseName: "靓仔",
          price: 2.96,
        },
        {
          number: "R9",
          name: "Coconut Rice",
          chineseName: "椰饭",
          price: 3.96,
        },
      ],
    },
    {
      category: "Extras",
      items: [
        {
          number: "E1",
          name: "Sweet & Sour Sauce",
          chineseName: "红水",
          price: 1.69,
        },
        {
          number: "E2",
          name: "Lemon Sauce",
          chineseName: "黄水",
          price: 1.69,
        },
        {
          number: "E3",
          name: "Ginger Sauce",
          chineseName: "牛C水",
          price: 1.69,
        },
        {
          number: "E4",
          name: "Foo Yung Sauce",
          chineseName: "芙蓉姐姐",
          price: 1.69,
        },
        {
          number: "E5",
          name: "Pop 355mL (Can)",
          chineseName: "炸弹 355mL (Can)",
          price: 1.96,
        },
        {
          number: "E6",
          name: "Pop 2L (Bottle)",
          chineseName: "大炮 2L (Bottle)",
          price: 4.96,
        },

        /*
                {
          number: "E6",
          name: "Arizona 680mL (Can)",
          chineseName: "冰绿茶 680mL (Can)",
          price: 2.96,
        },
  {
  number: "E5",
  name: "Tea",
  chineseName: "茶",
  price: 1.0,
  },*/
      ],
    },
  ],
};
