import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Flex, Button, Text, Icon } from "@chakra-ui/react";

import { FaPepperHot } from "react-icons/fa";

import PageOne from "./PageOne";
import CategorySection from "./CategorySection";
export default function NewMenu() {
  const componentRef = useRef();
  const componentRefTwo = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintTwo = useReactToPrint({
    content: () => componentRefTwo.current,
  });

  return (
    <Flex direction="column">
      <Flex>
        <Button mr="1rem" colorScheme="green" onClick={() => handlePrint()}>
          Print First Page
        </Button>
        <Button colorScheme="green" onClick={() => handlePrintTwo()}>
          Print Second Page
        </Button>
      </Flex>

      <Flex
        className="menuOne"
        ref={componentRef}
        direction="row"
        fontSize="menusm"
        height="100vh"
      >
        <style type="text/css" media="print">
          {" @page { size: landscape; } "}
        </style>

        <PageOne></PageOne>
      </Flex>

      <Flex
        ref={componentRefTwo}
        direction="row"
        fontSize="menusm"
        height="100vh"
      >
        <style type="text/css" media="print">
          {" @page { size: landscape; } "}
        </style>
        <Flex
          direction="column"
          width="25%"
          border="1px solid #A50104"
          bg="white"
        >
          <CategorySection
            category="Appetizers"
            index={1}
            marginFlag={true}
          ></CategorySection>
          <CategorySection
            category="Soup"
            index={2}
            marginFlag={true}
          ></CategorySection>
          <CategorySection
            category="Beef"
            index={4}
            marginFlag={true}
          ></CategorySection>

          <Flex
            width="100%"
            align="center"
            mt="auto"
            direction="column"
            mb="15px"
          >
            <Text>Please inform us if you have any food allergies</Text>

            <Flex align="center">
              <Icon mr="5px" as={FaPepperHot}></Icon>
              <Text>Indicates spicy</Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          direction="column"
          width="25%"
          border="1px solid #A50104"
          bg="white"
        >
          <CategorySection
            category="Pork"
            index={3}
            marginFlag={true}
          ></CategorySection>
          <CategorySection
            category="Chicken"
            index={5}
            marginFlag={true}
          ></CategorySection>

          <Flex
            width="100%"
            align="center"
            mt="auto"
            direction="column"
            mb="15px"
          >
            <Text>Please inform us if you have any food allergies</Text>

            <Flex align="center">
              <Icon mr="5px" as={FaPepperHot}></Icon>
              <Text>Indicates spicy</Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          direction="column"
          width="25%"
          border="1px solid #A50104"
          bg="white"
        >
          <CategorySection
            category="Seafood"
            index={6}
            marginFlag={true}
          ></CategorySection>
          <CategorySection
            category="Vegetables"
            index={7}
            marginFlag={true}
          ></CategorySection>

          <Flex
            width="100%"
            align="center"
            mt="auto"
            direction="column"
            mb="15px"
          >
            <Text>Please inform us if you have any food allergies</Text>

            <Flex align="center">
              <Icon mr="5px" as={FaPepperHot}></Icon>
              <Text>Indicates spicy</Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          width="25%"
          border="1px solid #A50104"
          bg="white"
        >
          <CategorySection
            category="Chop Suey"
            index={8}
            marginFlag={true}
          ></CategorySection>
          <CategorySection
            category="Egg Foo Yung"
            index={9}
            marginFlag={true}
          ></CategorySection>
          <CategorySection
            category="Rice"
            index={12}
            marginFlag={true}
          ></CategorySection>

          <Flex
            width="100%"
            align="center"
            mt="auto"
            direction="column"
            mb="15px"
          >
            <Text>Please inform us if you have any food allergies</Text>

            <Flex align="center">
              <Icon mr="5px" as={FaPepperHot}></Icon>
              <Text>Indicates spicy</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
