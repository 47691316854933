export const quickAccessOptions = [
  {
    option: "No MSG",
  },
  {
    option: "No Onions",
  },
  {
    option: "No Peppers",
  },
  {
    option: "No Mushrooms",
  },
  {
    option: "No Peas",
  },
  {
    option: "No Carrot",
  },
  {
    option: "No Celery",
  },

  {
    option: "No Corn",
  },
  {
    option: "No Bean Sprout",
  },
  {
    option: "Less Salt",
  },
  {
    option: "Less Oil",
  },
  {
    option: "Mild",
    special: true,
  },
  {
    option: "Sauce on Side",
    special: true,
  },
  {
    option: "Extra Sauce",
    special: true,
  },
];
