import React from "react";
import Navbar from "../components/main-page/Navbar";
import { Flex } from "@chakra-ui/react";
import Banner from "../components/order-page/Banner";
import Offers from "../components/order-page/Offers";
import Itemnav from "../components/order-page/Itemnav";
import Footer from "../components/main-page/Footer";

function Takeout(props) {
  return (
    <Flex width="100%" justify="center" direction="column" align="center">
      <Flex direction="column" width={{ base: "100%", "3xl": "1500px" }}>
        <Flex direction="column" align="center">
          <Navbar takeoutmode={true}></Navbar>
        </Flex>
        <Flex
          bg="primary"
          justify="center"
          color="secondary"
          mt={{ base: "70px", md: "80px" }}
        >
          <Flex
            width={{ base: "100%", "3xl": "100%" }}
            direction="column"
            position="relative"
          >
            <Banner></Banner>
          </Flex>
        </Flex>
        <Flex justify="center" color="primary" mb="6rem">
          <Flex
            width={{ base: "90%", lg: "90%", "3xl": "100%" }}
            direction="column"
          >
            <Offers></Offers>
            <Itemnav></Itemnav>
          </Flex>
        </Flex>
        <Flex bg="primary" justify="center">
          <Flex justify="center" w="100%" bg="brand.red" p="56px 20px">
            <Footer></Footer>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Takeout;
