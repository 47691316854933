import React, { useContext } from "react";
import { TakeoutContext } from "../../../context/TakeoutContext";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

export default function NewModal({
  onOpenNew,
  isOpenNew,
  onCloseNew,
  orderNote,
  setOrderNote,
}) {
  const cancelRef = React.useRef();
  const {
    takeoutCart,
    setTakeoutCart,
    orderHistory,
    setOrderHistory,
    deliveryFee,
    setDeliveryFee,
    updateDeliveryFee,
    setUpdateDeliveryFee,
    addCharges,
    setAddCharges,
    showCharge,
    setShowCharge,
    orderPrice,
    method,
    setMethod,
    customerName,
    setCustomerName,
    customerTable,
    setCustomerTable,
    customerPhone,
    setCustomerPhone,
    customerAddress,
    setCustomerAddress,
    setNextButton,
    uniqueInvoice,
    setUniqueInvoice,
    skipCode,
    setSkipCode,
  } = useContext(TakeoutContext);

  const dayjs = require("dayjs");
  var now = dayjs();

  const newOrder = () => {
    storeOrder();
    setUniqueInvoice("");
    setCustomerName("");
    setCustomerPhone("");
    setCustomerAddress("");
    setCustomerTable("");
    setSkipCode("");
    setMethod("3");
    setDeliveryFee(0);
    setUpdateDeliveryFee(0);
    setAddCharges(0);
    setShowCharge(0);
    setOrderNote("");
    setTakeoutCart([]);
    setNextButton(0);
  };

  const storeOrder = () => {
    let orderObj = {
      name: customerName,
      phone: customerPhone,
      address: customerAddress,
      skipCode: skipCode,
      method: method,
      deliveryFee: deliveryFee,
      updateDeliveryFee: updateDeliveryFee,
      addCharges: addCharges,
      showCharge: showCharge,
      takeoutCart: takeoutCart,
      currentInvoiceNumber: uniqueInvoice,
      orderPrice: orderPrice,
      time: now.format("HH:mm"),
      orderNote: orderNote,
      toggle: false,
      table: customerTable,
    };

    let tempOrderHistory = orderHistory;

    //check if modified order is in history and update it accordingly
    const checkModifiedOrder = tempOrderHistory.some(
      (obj) => obj.currentInvoiceNumber === uniqueInvoice
    );
    if (checkModifiedOrder) {
      const index = tempOrderHistory.findIndex(
        (obj) => obj.currentInvoiceNumber === uniqueInvoice
      );
      tempOrderHistory[index] = orderObj;
    } else {
      tempOrderHistory.unshift(orderObj);
    }
    sessionStorage.setItem("orderHistory", JSON.stringify(tempOrderHistory));
    setOrderHistory(tempOrderHistory);
  };

  return (
    <Modal
      isOpen={isOpenNew}
      onClose={onCloseNew}
      isCentered
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="bold">
          Save & Start a New Order?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure? You can't undo this action afterwards.
        </ModalBody>

        <ModalFooter>
          <Button ref={cancelRef} onClick={onCloseNew} colorScheme="red">
            Cancel
          </Button>
          <Button
            colorScheme="green"
            ml={3}
            onClick={() => {
              newOrder();
              onCloseNew();
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
