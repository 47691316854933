import React, { useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import theme from "./theme";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import Error from "./pages/Error";
import Takeout from "./pages/Takeout";
import PrivateRoute from "./components/PrivateRoute";
import { TakeoutContext } from "./context/TakeoutContext";
import { CartContext } from "./context/CartContext";
import "./App.css";
import Checkout from "./pages/Checkout";
import "@fontsource/langar";
import "@fontsource/glory";
function App() {
  const [cart, setCart] = useState([]);
  const [discount, setDiscount] = useState({
    percentage: 0,
    item: "none",
  });

  const [subTotal, setSubTotal] = useState(0.0);
  const [takeoutCart, setTakeoutCart] = useState([]);
  const [loadOrderIndex, setLoadOrderIndex] = useState();
  const [orderHistory, setOrderHistory] = useState([]);

  const [uniqueInvoice, setUniqueInvoice] = useState();

  //control panel state
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [updateDeliveryFee, setUpdateDeliveryFee] = useState(0);
  const [addCharges, setAddCharges] = useState(0);
  const [showCharge, setShowCharge] = useState(0);
  const [updateToggle, setUpdateToggle] = useState(false);
  const [orderPrice, setOrderPrice] = useState({
    subTotal: 0,
    discount: 0,
    subTotalDiscount: 0,
    additionalCharges: 0,
    deliveryFee: 0,
    gst: 0,
    total: 0,
  });

  //const [orderTimeStore, setOrderTimeStore] = useState();
  const [method, setMethod] = useState("3");
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerTable, setCustomerTable] = useState("");
  const [skipCode, setSkipCode] = useState("");
  const [orderNote, setOrderNote] = useState("");
  const [nextButton, setNextButton] = useState(0);
  return (
    <ChakraProvider theme={theme}>
      <CartContext.Provider
        value={{
          cart,
          setCart,
          discount,
          setDiscount,
          subTotal,
          setSubTotal,
        }}
      >
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="takeout" element={<Takeout />} />

          <Route path="login" element={<Login />} />

          <Route path="checkout" element={<Checkout />} />

          <Route
            path="admin"
            element={
              <PrivateRoute>
                <TakeoutContext.Provider
                  value={{
                    takeoutCart,
                    setTakeoutCart,
                    orderHistory,
                    setOrderHistory,
                    deliveryFee,
                    setDeliveryFee,
                    updateDeliveryFee,
                    setUpdateDeliveryFee,
                    addCharges,
                    setAddCharges,
                    showCharge,
                    setShowCharge,
                    updateToggle,
                    setUpdateToggle,
                    orderPrice,
                    setOrderPrice,
                    //orderTimeStore,
                    //setOrderTimeStore,
                    method,
                    setMethod,
                    customerName,
                    setCustomerName,
                    customerPhone,
                    setCustomerPhone,
                    customerAddress,
                    setCustomerAddress,
                    skipCode,
                    setSkipCode,
                    nextButton,
                    setNextButton,
                    customerTable,
                    setCustomerTable,
                    loadOrderIndex,
                    setLoadOrderIndex,
                    orderNote,
                    setOrderNote,
                    uniqueInvoice,
                    setUniqueInvoice,
                  }}
                >
                  <Admin />
                </TakeoutContext.Provider>
              </PrivateRoute>
            }
          />

          <Route path="*" element={<Error />} />
        </Routes>
      </CartContext.Provider>
    </ChakraProvider>
  );
}

export default App;
