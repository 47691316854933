import React from "react";
import { Button, Text } from "@chakra-ui/react";
function ItemSelection({
  selection,
  pickedItems,
  setPickedItems,
  trigger,
  setTrigger,
  choices,
}) {
  const handleDinnerOne = (name, chineseName, code) => {
    let nameObj = { name: name, chineseName: chineseName, code: code };
    let tempPickedItems = pickedItems;
    tempPickedItems.push(nameObj);
    setPickedItems(tempPickedItems);
  };

  return (
    <Button
      colorScheme="blue"
      variant="outline"
      isDisabled={pickedItems.length.toString() === choices ? true : false}
      onClick={() => {
        handleDinnerOne(selection.name, selection.chineseName, selection.code);
        setTrigger(!trigger);
      }}
      justify="flex-start"
    >
      <Text fontWeight="bold" color="#111111" align="left">
        {selection.code}: {selection.name}
      </Text>
    </Button>
  );
}

export default ItemSelection;
