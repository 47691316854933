import React from "react";
import { Flex, Text } from "@chakra-ui/react";

function About() {
  return (
    <Flex
      align="center"
      justify="center"
      width="100%"
      height="100%"
      bg="brand.red"
      color="brand.beige"
      fontFamily="glory"
      fontSize={{ base: "mobile.main", md: "main" }}
      lineHeight={1.25}
    >
      <Flex
        direction="column"
        align="center"
        justify="center"
        textAlign="center"
        width="510px"
        height="100%"
        pt={{ base: "100px", sm: "100px", md: "125px" }}
        pb={{ base: "100px", sm: "100px", md: "125px" }}
        pl={{ base: "20px", md: "0" }}
        pr={{ base: "20px", md: "0" }}
      >
        <Text>
          Kowloon Kitchen presents a relaxing dining experience in the heart of
          Calgary. Food is our way of showcasing the authentic flavors you know
          and love about Chinese cuisine.
        </Text>
        <Text mt="20px">We look forward to serving you!</Text>
      </Flex>
    </Flex>
  );
}

export default About;
