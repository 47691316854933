import { extendTheme } from "@chakra-ui/react";

// 2. Update the breakpoints as key-value pairs
const breakpoints = {
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
  "3xl": "140em",
};

const colors = {
  brand: {
    red: "#A50104",
    darkred: "#800002",
    beige: "#F7EEDD",
    beige2: "#FFF9EE",
    black: "#06141E",
    gold: "#F2A202",
    white: "#FFFFFF",
  },
};

const fonts = {
  langar: "Langar, sans-serif",
  chinese: "Ma Shan Zheng, cursive",
  glory: "Glory, sans-serif",
};

const fontSizes = {
  main: "20px",
  subheader: "16px",
  header: "32px",
  buttonSize: "16px",
  chineseLetter: "18px",
  mobile: {
    main: "18px",
    subheader: "14px",
    header: "28px",
    buttonSize: "14px",
    chineseLetter: "16px",
  },
  cxs: "16px",
  csm: "20px",
  cnav: "24px",
  cmd: "28px",
  clg: "36px",
  cxl: "48px",
  navbar: {
    logo: "22px",
    mobilelogo: "20px",
    item: "18px",
    header: "14px",
  },
  footer: {
    base: "16px",
    sm: "14px",
    logo: "42px",
    logosm: "28px",
    caps: "72px",
    capssm: "60px",
  },
  copyrightsm: "18px",
  menusm: "14px",
  menumd: "16px",
  menulg: "18px",
};

const constants = {
  navbarHeight: "80px",
};

// 3. Extend the theme
const theme = extendTheme({ breakpoints, colors, fonts, fontSizes, constants });

export default theme;
