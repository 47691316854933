import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
} from "@chakra-ui/react";

function UpdateModal(props) {
  const initialRef = React.useRef(null);

  const [error, setError] = useState(false);

  const [additionalCharges, setAdditionalCharges] = useState(0.0);

  const updateOrder = () => {
    let tempList = props.customerOrders;
    tempList[props.orderIndex].data.additionalCharges = additionalCharges;
    props.setOrders(tempList);
    setAdditionalCharges(0.0);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={() => {
        //reset back to 0
        setAdditionalCharges(0.0);
        props.onClose();
      }}
      initialFocusRef={initialRef}
      size="xl"
      isCentered
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Update Total For:{" "}
          {props.customerOrder !== null ? props.customerOrder.data.name : null}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Enter Addtional Charges ($)</FormLabel>
            <Input
              isInvalid={error ? true : false}
              ref={initialRef}
              placeholder="0.00"
              type="number"
              onChange={(e) => {
                setAdditionalCharges(e.target.value);
              }}
            />
            <FormHelperText>Example: 2.00</FormHelperText>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => {
              updateOrder();
              props.onClose();
            }}
            colorScheme="green"
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UpdateModal;
