import React, { useState, useContext } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Icon,
  FormControl,
  FormLabel,
  FormHelperText,
  Textarea,
  RadioGroup,
  VStack,
  Radio,
  SimpleGrid,
  Divider,
  Input,
  Checkbox,
} from "@chakra-ui/react";

import ItemSelection from "./ItemSelection";
import {
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
  AiFillExclamationCircle,
  AiFillCloseCircle,
} from "react-icons/ai";
import { quickAccessOptions } from "../../data/quickaccess";
import { TakeoutContext } from "../../context/TakeoutContext";

function TakeoutPopup({ isOpen, onOpen, onClose, item }) {
  const { takeoutCart, setTakeoutCart, method } = useContext(TakeoutContext);
  const [quantity, setQuantity] = useState(1);
  const [note, setNote] = useState("");
  const [size, setSize] = useState("small");
  const [customItemName, setCustomItemName] = useState("");
  const [customItemPrice, setCustomItemPrice] = useState(0.0);
  const [halfOrder, setHalfOrder] = useState(false);
  //Dinner for one
  const [pickedItems, setPickedItems] = useState([]);
  const [dinnerOneChoice, setDinnerOneChoice] = useState("1");
  const [trigger, setTrigger] = useState(false);

  //quick access options

  //half orders dont apply to these single items
  const halfOrderExemption = ["A3", "U1", "U2", "U3", "U4", "U5", "E5", "E6"];

  //soups 20% | quantity > 2
  const soupList = ["U1", "U2", "U3", "U4", "U5"];

  const minus = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const add = () => {
    if (quantity < 19 && halfOrder === false) {
      setQuantity(quantity + 1);
    }
  };

  const noteHandler = (e) => {
    setNote(e.target.value);
  };

  const updatePrice = (e, price) => {
    if (e.target.value === "large") {
      setSize(e.target.value);
    } else {
      setSize("small");
    }
  };

  const handleCustomItemName = (event) => {
    setCustomItemName(event.target.value);
  };
  const handleCustomItemPrice = (event) => {
    setCustomItemPrice(event.target.value);
  };

  function RoundNum(num, length) {
    var number = Math.round(num * Math.pow(10, length)) / Math.pow(10, length);
    return number;
  }

  const addToTakeoutCart = (item) => {
    const tempCart = [...takeoutCart];
    //old soup version
    //check if soup is small or large and set the price depending on the size
    const tempSize = item.sizes ? size : undefined;

    //new soup version
    //20% off second or more soup, cannot mix and match
    let soupDiscountPrice = 0;
    let soupDiscountQuantity = quantity - 1;
    const soupDiscountPercentage = 0.2;
    let soupDiscountFlag = false;

    //check if soup is already in our cart
    if (
      tempCart.some((x) => x.name === item.name) &&
      soupList.includes(item.number) &&
      quantity === 1 &&
      (method === "1" || method === "2")
    ) {
      soupDiscountFlag = true;
      soupDiscountPrice = RoundNum(item.price - item.price * 0.2, 2);
    }

    if (
      soupList.includes(item.number) &&
      quantity > 1 &&
      (method === "1" || method === "2")
    ) {
      soupDiscountFlag = true;
      let tempAmountOff =
        RoundNum(item.price * soupDiscountPercentage, 2) * soupDiscountQuantity;
      let discountedSoups = RoundNum(
        item.price * soupDiscountQuantity - tempAmountOff,
        2
      );
      soupDiscountPrice = RoundNum(item.price + discountedSoups, 2);
    }

    //handle dinner for one price
    let dOnePrice = 0;
    if (item.special) {
      if (dinnerOneChoice === "1") {
        dOnePrice = item.prices.oneItem.price;
      } else if (dinnerOneChoice === "2") {
        dOnePrice = item.prices.twoItem.price;
      } else if (dinnerOneChoice === "3") {
        dOnePrice = item.prices.threeItem.price;
      }
    }

    let price = item.price;

    if (halfOrder) {
      if (item.number === "A1" || item.number === "A2") {
        price = RoundNum(price / 2, 2);
      } else if (item.number === "R8") {
        //rice half order
        price = 2.96;
      } else {
        price = RoundNum(price / 2, 2) + 0.8;
      }
    } else {
      //price = price * quantity;
    }

    /*
    let soupPrice = 0;
    if (soupList.includes(item.number) && quantity > 1) {
    }
    */

    const itemObj = {
      name: item.customItem ? customItemName : item.name,
      number: item.number,
      price:
        tempSize === "large"
          ? item.large
          : item.special
          ? dOnePrice
          : item.customItem
          ? customItemPrice
          : price,
      note: note,
      quantity: quantity,
      itemSize: item.sizes ? size : undefined,
      chineseName: item.chineseName,
      combo: item.combo ? true : false,
      comboPickedItems: pickedItems,
      special: item.special,
      includes: item.includes,
      half: halfOrder,
      soupDiscountFlag: soupDiscountFlag,
      soupDiscountPrice: soupDiscountPrice,
    };

    tempCart.push(itemObj);
    setTakeoutCart(tempCart);
    setQuantity(1);
    setNote("");
    setSize("small");
    setPickedItems([]);
    setHalfOrder(false);
    setDinnerOneChoice("1");
  };

  const handleChange = (value) => {
    setPickedItems([]);
    setDinnerOneChoice(value);
  };

  const showPriceHandler = (price, itemNumber) => {
    if (halfOrder) {
      if (itemNumber === "A1" || itemNumber === "A2") {
        price = RoundNum(price / 2, 2);
      } else if (item.number === "R8") {
        price = 2.96;
      } else {
        price = RoundNum(price / 2, 2) + 0.8;
      }
    } else {
      price = price * quantity;
    }

    return price.toFixed(2);
  };

  const handleNote = (options) => {
    setNote(note + " -" + options.option + ",");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setQuantity(1);
        setNote("");
        setSize("small");
        setPickedItems([]);
        setDinnerOneChoice("1");
        setHalfOrder(false);
        onClose();
      }}
      size="xl"
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent
        ml={{ base: "1rem", md: "0rem" }}
        mr={{ base: "1rem", md: "0rem" }}
      >
        <ModalHeader width="90%">
          {item.number
            ? item.number + ". " + item.name + ` (${item.chineseName})`
            : item.name + `(${item.chineseName})`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column">
            {item.customItem ? (
              <Flex direction="column">
                <Text fontWeight="bold">Name</Text>
                <Input
                  mt="7px"
                  borderColor="#c4c4c4"
                  placeholder="ex. Ginger Chicken"
                  size="lg"
                  onChange={(e) => {
                    handleCustomItemName(e);
                  }}
                />
                <Text fontWeight="bold" mt="1rem">
                  Price
                </Text>
                <Input
                  mt="7px"
                  borderColor="#c4c4c4"
                  placeholder="ex. 12.99"
                  size="lg"
                  onChange={(e) => {
                    handleCustomItemPrice(e);
                  }}
                />
              </Flex>
            ) : null}

            {item.combo ? (
              <>
                <Text>
                  <b>Includes: </b>
                  {item.includes.map((incItem) => {
                    return incItem.name + ", ";
                  })}
                </Text>
                <Divider
                  width="100%"
                  borderColor="#c4c4c4"
                  mt="1rem"
                  mb="1rem"
                ></Divider>
                <Text mb="1rem" fontWeight="bold" fontSize="lg">
                  Select: {item.choices} Option (选 {item.choices} 个)
                </Text>
                <Flex
                  direction="column"
                  border={"1px solid #c4c4c4"}
                  minHeight="85px"
                  borderColor="#c4c4c4"
                  borderRadius="5px"
                  justify="center"
                >
                  {pickedItems.map((comboItem, i) => {
                    return (
                      <Text ml="5px" mr="5px" key={"comboI" + i}>
                        -{comboItem.code}: {comboItem.name} (
                        {comboItem.chineseName})
                      </Text>
                    );
                  })}
                </Flex>
                <SimpleGrid columns={2} gap={3} mt="1rem">
                  {item.selection.map((selection, j) => {
                    return (
                      <ItemSelection
                        key={"comboD" + j}
                        selection={selection}
                        choices={item.choices}
                        pickedItems={pickedItems}
                        setPickedItems={(pickedItems) => {
                          setPickedItems(pickedItems);
                        }}
                        trigger={trigger}
                        setTrigger={(pickedItems) => setTrigger(pickedItems)}
                      ></ItemSelection>
                    );
                  })}
                  <Button
                    leftIcon={<AiFillCloseCircle />}
                    colorScheme="red"
                    onClick={() => {
                      let tempPickedItems = pickedItems;
                      tempPickedItems.pop();
                      setPickedItems(tempPickedItems);
                      setTrigger(!trigger);
                    }}
                  >
                    Remove Item
                  </Button>
                </SimpleGrid>
              </>
            ) : null}

            {item.special ? (
              <>
                <RadioGroup
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={dinnerOneChoice}
                >
                  <Flex direction="column" fontWeight="bold">
                    <Radio size="lg" value="1">
                      One Item - ${item.prices.oneItem.price.toFixed(2)}
                    </Radio>
                    <Radio size="lg" value="2">
                      Two Item - ${item.prices.twoItem.price.toFixed(2)}
                    </Radio>
                    <Radio size="lg" value="3">
                      Three Item - ${item.prices.threeItem.price.toFixed(2)}
                    </Radio>
                  </Flex>
                </RadioGroup>
                <Divider
                  width="100%"
                  borderColor="#c4c4c4"
                  mt="1rem"
                  mb="1rem"
                ></Divider>
                <Text mb="1rem" fontWeight="bold" fontSize="lg">
                  Select: {dinnerOneChoice} Option (选 {dinnerOneChoice} 个)
                </Text>

                <Flex
                  direction="column"
                  border={"1px solid #c4c4c4"}
                  height="85px"
                  borderColor="#c4c4c4"
                  borderRadius="5px"
                  justify="center"
                >
                  {pickedItems.map((comboItem, i) => {
                    return (
                      <Text ml="5px" mr="5px" key={"selc" + i}>
                        -{comboItem.code}: {comboItem.name} (
                        {comboItem.chineseName})
                      </Text>
                    );
                  })}
                </Flex>
                <SimpleGrid columns={2} gap={3} mt="1rem">
                  {item.selection.map((selection, j) => {
                    return (
                      <ItemSelection
                        key={"d1" + j}
                        selection={selection}
                        choices={dinnerOneChoice}
                        pickedItems={pickedItems}
                        setPickedItems={(pickedItems) => {
                          setPickedItems(pickedItems);
                        }}
                        trigger={trigger}
                        setTrigger={(pickedItems) => setTrigger(pickedItems)}
                      ></ItemSelection>
                    );
                  })}
                  <Button isDisabled colorScheme="blue" variant="outline">
                    Disabled
                  </Button>
                  <Button
                    leftIcon={<AiFillCloseCircle />}
                    colorScheme="red"
                    onClick={() => {
                      let tempPickedItems = pickedItems;
                      tempPickedItems.pop();
                      setPickedItems(tempPickedItems);
                      setTrigger(!trigger);
                    }}
                  >
                    Remove Item
                  </Button>
                </SimpleGrid>
              </>
            ) : null}
          </Flex>
          <FormControl>
            {item.sizes ? (
              <>
                <FormLabel htmlFor="size">Choose Size</FormLabel>
                <RadioGroup defaultValue="small">
                  <VStack align="flex-start">
                    <Radio
                      colorScheme="red"
                      value="small"
                      onChange={(e) => {
                        updatePrice(e, item.price);
                        setQuantity(1);
                      }}
                    >
                      <Text fontSize="14px">
                        Small - ${item.price.toFixed(2)}
                      </Text>
                    </Radio>
                    <Radio
                      colorScheme="red"
                      value="large"
                      onChange={(e) => {
                        updatePrice(e, item.large);
                        setQuantity(1);
                      }}
                    >
                      <Text fontSize="14px">
                        Large - ${item.large.toFixed(2)}
                      </Text>
                    </Radio>
                  </VStack>
                </RadioGroup>
              </>
            ) : null}

            {!item.combo &&
            !item.special &&
            !halfOrderExemption.includes(item.number) ? (
              <>
                <FormLabel
                  fontWeight="bold"
                  htmlFor="special-instructions"
                  mt="1rem"
                >
                  Half Order Option
                </FormLabel>
                <Checkbox
                  colorScheme="green"
                  isChecked={halfOrder}
                  onChange={(e) => setHalfOrder(e.target.checked)}
                >
                  Half Order
                </Checkbox>
              </>
            ) : null}

            <Flex direction="column">
              <FormLabel
                fontWeight="bold"
                htmlFor="special-instructions"
                mt="1rem"
              >
                Quick Access
              </FormLabel>

              <SimpleGrid columns={3} gap={3}>
                {quickAccessOptions.map((options, i) => {
                  return (
                    <Button
                      width="100%"
                      leftIcon={
                        options.special ? (
                          <AiFillExclamationCircle />
                        ) : (
                          <AiFillCloseCircle />
                        )
                      }
                      key={"opb" + i}
                      colorScheme={options.special ? "blue" : "red"}
                      size="sm"
                      variant="outline"
                      onClick={() => handleNote(options)}
                    >
                      {options.option}
                    </Button>
                  );
                })}
              </SimpleGrid>
            </Flex>

            <FormLabel
              fontWeight="bold"
              htmlFor="special-instructions"
              mt="1rem"
            >
              Special Instructions
            </FormLabel>
            <Textarea
              type="text"
              id="special-instructions"
              resize="none"
              maxLength="175"
              borderColor="#c4c4c4"
              value={note}
              onChange={(e) => noteHandler(e)}
            />
            <FormHelperText>Example: No Mushrooms</FormHelperText>
          </FormControl>
        </ModalBody>

        <ModalFooter as={Flex} justify="space-between">
          <Flex width="50%" align="center" justify="center">
            <Flex
              align="center"
              width={{ base: "75%", md: "55%" }}
              justify="space-between"
            >
              <Icon
                w={7}
                h={7}
                cursor="pointer"
                as={AiOutlineMinusCircle}
                onClick={() => minus()}
                opacity={quantity === 1 || quantity === 0.5 ? "40%" : "100%"}
              />
              <Text>{halfOrder ? 0.5 : quantity}</Text>
              <Icon
                w={7}
                h={7}
                cursor="pointer"
                as={AiOutlinePlusCircle}
                onClick={() => add()}
                opacity={quantity === 9 || quantity === 0.5 ? "40%" : "100%"}
              />
            </Flex>
          </Flex>
          <Button
            width="70%"
            variant="solid"
            bg="black"
            color="white"
            _hover={{ bg: "#494949" }}
            onClick={() => {
              addToTakeoutCart(item);
              setQuantity(1);
              onClose();
            }}
            isDisabled={item.special && pickedItems.length === 0 ? true : false}
          >
            Add to Cart
            {item.special
              ? null
              : size === "small"
              ? " - $" + showPriceHandler(item.price, item.number)
              : " - $" + (item.large * quantity).toFixed(2)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TakeoutPopup;
