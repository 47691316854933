import React from "react";
import {
  Flex,
  Text,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Image,
  Link as ChakraLink,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";

import { ChevronRightIcon } from "@chakra-ui/icons";

function MobileNav(props) {
  return (
    <Drawer
      isOpen={props.isOpen}
      placement="right"
      onClose={props.onClose}
      returnFocusOnClose={false}
    >
      <DrawerOverlay />
      <DrawerContent bg="brand.beige2" maxWidth="250px">
        <Flex
          align="center"
          borderTopWidth="5px"
          borderColor="brand.red"
          height="80px"
          justify="flex-end"
        >
          <DrawerCloseButton
            width="20px"
            height="20px"
            mr="0.5rem"
            mt="2rem"
            p="1rem"
            color="brand.darkred"
            bg="brand.beige"
            borderRadius="25px"
            _hover={{ bg: "#E6DCC9" }}
          />
        </Flex>

        <DrawerBody fontFamily="glory" fontWeight="bold">
          <Flex width="100%" direction="column" mt="2rem">
            <Flex
              direction="column"
              align="flex-start"
              mb="3rem"
              lineHeight={1}
            >
              <Text color="black" mb="1rem" fontSize="navbar.header">
                MAIN
              </Text>

              <Link to="/">
                <Text
                  mb="16px"
                  fontSize="navbar.item"
                  color="brand.red"
                  transition="all 0.1s ease-in-out"
                  _hover={{ color: "brand.darkred" }}
                  onClick={props.onClose}
                >
                  Home
                </Text>
              </Link>

              <ChakraLink href="/menu.pdf" isExternal>
                <Text
                  fontSize="navbar.item"
                  mb="16px"
                  color="brand.red"
                  transition="all 0.1s ease-in-out"
                  _hover={{ color: "brand.darkred" }}
                  onClick={props.onClose}
                >
                  Menu
                </Text>
              </ChakraLink>

              <Link to="/takeout">
                <Text
                  fontSize="navbar.item"
                  color="brand.red"
                  transition="all 0.1s ease-in-out"
                  _hover={{ color: "brand.darkred" }}
                  onClick={props.onClose}
                >
                  Order Pick Up
                </Text>
              </Link>
            </Flex>

            <Text color="black" mb="1rem" fontSize="navbar.header">
              ORDER DELIVERY
            </Text>

            <Flex
              href="https://www.skipthedishes.com/kens-chinese-restaurant-2118"
              as={ChakraLink}
              isExternal
              direction="row"
              justify="space-between"
              mb="1rem"
              color="brand.red"
              _hover={{ color: "brand.darkred" }}
            >
              <Flex align="center">
                <Image
                  src="/skipthedish.png"
                  alt="SkipTheDishes"
                  width={30}
                  height={30}
                />
                <Text ml="9px">SkipTheDishes</Text>
              </Flex>
              <ChevronRightIcon alignSelf="center" h={5} w={5} />
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default MobileNav;
